import React, { useCallback, useEffect } from 'react';
import { useFeeCalculator } from 'queries/logistic_service.query';
import { TypedOrder } from 'queries/orders.query';
import { BlockStack, Divider, InlineStack, SkeletonBodyText, SkeletonTabs, Text } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import __helpers from 'helpers/index';
export default function FeeCalculator({
  entity,
  product,
  title
}: {
  entity: TypedOrder;
  product: any[];
  title: string;
}) {
  const {
    data,
    mutateAsync: feeCalculator,
    isSuccess,
    isPending
  } = useFeeCalculator();
  const calculatorFee = useCallback(async () => {
    try {
      await feeCalculator({
        products: product,
        address: {
          receiver_phonenumber: entity?.order_fulfillment?.receiver_phonenumber,
          receiver_fullname: entity?.order_fulfillment?.receiver_fullname,
          receiver_address: entity?.order_fulfillment?.receiver_address,
          receiver_address_ward: entity?.order_fulfillment?.receiver_address_ward,
          receiver_address_district: entity?.order_fulfillment?.receiver_address_district,
          receiver_address_city: entity?.order_fulfillment?.receiver_address_city
        }
      });
    } catch (e) {}
  }, [entity, product]);
  useEffect(() => {
    calculatorFee();
  }, []);
  return isPending ? <BlockStack gap="400">
      <SkeletonBodyText lines={2} key="a0_997" />
      <SkeletonTabs />
      <SkeletonBodyText lines={2} key="a1_9987hjk" />
    </BlockStack> : <BlockStack gap="400">
      <Text as="h3" variant="headingSm">
        {title}
      </Text>
      <Divider />

      {Array.isArray(data) ? data.map((el, index) => {
      return <div key={`order_fee_${index}_cal`}>
              <Text as="h3" variant="headingMd">
                {el.logistic_name}
              </Text>
              <InlineStack gap="200">
                <Text as="span" tone="critical" variant="headingMd" fontWeight="bold">
                  {el.estimated_price < 0 ? 'Chưa ước tính được giá' : `${__helpers.formatNumber(el.estimated_price)} đ`}
                </Text>
                {el.estimated_price > -1 && <Text as="span" tone="subdued">
                    {el.expected_delivery_date ? dateandtime.format(new Date(Number(el.expected_delivery_date)), 'Giao lúc DD-MM-YYYY') : 'Chưa tính được ngày giao'}
                  </Text>}
              </InlineStack>
            </div>;
    }) : <Text as="p">Chưa có dịch vụ nào</Text>}
    </BlockStack>;
}