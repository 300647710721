import __helpers from 'helpers/index';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

// Định nghĩa kiểu dữ liệu cho context
interface ISocketContext {
  socket: Socket | null;
}

// Tạo Context
const SocketContext = createContext<ISocketContext | undefined>(undefined);
interface SocketProviderProps {
  children: ReactNode;
}
export const SocketProvider: React.FC<SocketProviderProps> = ({
  children
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    // Kết nối socket khi component mount
    const socketInstance = io(process.env.REACT_APP_BACKEND_URL + '/socket', {
      extraHeaders: {
        'X-Authorization': __helpers.cookie_get('AT')
      }
    });
    socketInstance.emit('connection', '');
    setSocket(socketInstance);

    // Hủy kết nối khi component unmount
    return () => {
      socketInstance.disconnect();
      console.log('Socket disconnected');
    };
  }, []);
  return <SocketContext.Provider value={{
    socket
  }}>{children}</SocketContext.Provider>;
};

// Custom hook để sử dụng socket
export const useSocket = (): ISocketContext => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};