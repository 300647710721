import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import banner_list from './banner.list';
import banner_detail from './banner.editscreen';

/**
 *   Create index file
 */

export default function BannerIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = banner_list;
      break;
    case 'view':
      ActualPage = banner_detail;
      break;
    case 'new':
      ActualPage = banner_detail;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}