import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import device_list from './device.list';

/**
 *   Create index file for Comment
 */

export default function Devices() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = device_list;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}