import React, { useState, useEffect, useCallback } from 'react';
import { ActionList, Text, Button, Card, IndexTable, InlineStack, Link, Page, Popover, SkeletonTabs, Thumbnail, useIndexResourceState, Divider } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import ProductCollectionModal from './product_collection.edit.modal';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteProductCollection, useGetProductCollection, useUpdateProductCollection } from 'queries/product_collections.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateProductToCollecton, useDeleteProductToCollection, useGetProductToCollections } from 'queries/product_to_collection.query';
import { useNotification } from 'context/NotificationContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PinIcon, PinFilledIcon } from '@shopify/polaris-icons';
import { usePinProductToTopCollection, useRemovePinProductToTopCollection, useBatchReorder } from 'queries/product_to_collection.query';
import { Tooltip } from '@shopify/polaris';
export default function ProductCollectionView() {
  let {
    collection_id
  } = useParams();
  const {
    addNotification
  } = useNotification();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductCollection(collection_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductCollection();
  const {
    mutateAsync: updateEntity
  } = useUpdateProductCollection();
  const {
    mutateAsync: pinMe
  } = usePinProductToTopCollection();
  const {
    mutateAsync: unpinMe
  } = useRemovePinProductToTopCollection();
  const {
    mutate: batchReorder
  } = useBatchReorder();

  /** relationship */
  const {
    data: productToCollectionData,
    refetch: getRelationship
  } = useGetProductToCollections({
    limit: 100,
    collection_id: String(collection_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship,
    isSuccess: successAddProductToCollection
  } = useCreateProductToCollecton();
  const {
    mutateAsync: deleteRelationship
  } = useDeleteProductToCollection();
  useEffect(() => {
    if (successAddProductToCollection) addNotification('info', 'Thêm sản phẩm vào bộ sưu tập thành công.');
  }, [successAddProductToCollection]);
  const [allRelationship, setAllRelationship] = useState([]);
  useEffect(() => {
    if (productToCollectionData) {
      let {
        entities
      } = productToCollectionData;
      setAllRelationship(entities);
    }
  }, [productToCollectionData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async collection_id => {
    const confirmed = await showDeleteConfirm('Xóa bộ sưu tập này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(collection_id);
      history('/product_collection');
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const quickUpdateSetting = useCallback(async (status: number) => {
    setUpdateLoading(true);
    setActiveQuickUpdate(false);
    await updateEntity({
      collection_id: entity.collection_id,
      collection_status: status
    });
    setUpdateLoading(false);
  }, [entity]);
  const resourceIDResolver = ({
    product
  }) => {
    return product.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(allRelationship ?? [], {
    resourceIDResolver
  });
  const [bulkDeleting, setBulkDeleting] = useState(false);
  const bulkDeleteActions = useCallback(async () => {
    setBulkDeleting(true);
    await helpers.sleep(1000);
    try {
      await deleteRelationship({
        collection_id: collection_id,
        product_id: helpers.ArrayToComma(selectedResources)
      });
    } catch (e) {}
    setBulkDeleting(false);
    clearSelection();
  }, [selectedResources]);
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    let cloneEntities = [...allRelationship];
    const [reorderedItem] = cloneEntities.splice(result.source.index, 1);
    cloneEntities.splice(result.destination.index, 0, reorderedItem);
    setAllRelationship(cloneEntities);
    let reorderData = {};
    let index = 0;
    for (let d of cloneEntities) {
      index++;
      reorderData = {
        ...reorderData,
        ...{
          [d.id]: index
        }
      };
    }
    batchReorder(reorderData);
  }, [allRelationship]);
  const DisplayListOfProduct = () => {
    if (allRelationship === null) return <SkeletonTabs />;
    if (Array.isArray(allRelationship) && allRelationship.length < 1) return <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào bộ sưu tập.
        </Text>;
    return <Card padding="0">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="Polaris-DataTable__ScrollContainer">
            <Droppable droppableId="table">
              {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                  <thead>
                  <tr>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">STT</th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Tên sản phẩm
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Tình trạng
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Giá bán
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--header">
                      Giá gốc
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                      -
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {allRelationship.map(({
                  product,
                  pin_to_top,
                  id
                }, index) => {
                  const {
                    product_id,
                    product_name,
                    product_price,
                    product_original_price,
                    product_status,
                    product_thumbnail_to_media
                  } = product;
                  return <Draggable key={id} draggableId={id} index={index}>
                        {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              <InlineStack align="start" blockAlign="center" gap={'400'}>
                                <Thumbnail size="small" source={helpers.getThumbnailURI(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail : undefined, '128x128', '128x128')} alt={'product thumbnail: ' + product_name} />
                                <Button variant="plain" url={'/product/edit/' + product_id} icon={pin_to_top && PinIcon}>
                                  {product_name}
                                </Button>
                              </InlineStack>
                            </td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{product_status}</td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {helpers.formatNumber(product_price, ',')}
                            </td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {helpers.formatNumber(product_original_price, ',')}
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {pin_to_top ? <Tooltip content="Bỏ ghim sản phẩm">
                                  <Button variant="plain" icon={PinFilledIcon} onClick={() => {
                            unpinMe(id);
                          }}></Button>
                                </Tooltip> : <Tooltip content="Ghim sản phẩm lên đầu">
                                  <Button variant="plain" icon={PinIcon} onClick={() => {
                            pinMe(id);
                          }}></Button>
                                </Tooltip>}
                            </td>
                          </tr>}
                      </Draggable>;
                })}
                  {provided.placeholder}
                  </tbody>
                </table>}
            </Droppable>
          </div>
        </DragDropContext>
      </Card>;
  };
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.collection_name} subtitle={entity.collection_excerpt} titleMetadata={<InlineStack gap="200">
          {/* <Badge tone="success">{entity.app_type}</Badge> */}
          <Popover active={activeQuickUpdate} activator={<Button loading={updateLoading} variant="tertiary" disclosure={'down'} onClick={() => setActiveQuickUpdate(true)}>
                {entity.collection_status === 1 ? 'Hiển thị' : 'Không hiển thị'}
              </Button>} autofocusTarget="first-node" onClose={() => setActiveQuickUpdate(false)}>
            <ActionList actionRole="menuitem" items={[{
        content: 'Hiển thị',
        onAction: () => quickUpdateSetting(1)
      }, {
        content: 'Không hiển thị',
        onAction: () => quickUpdateSetting(0)
      }]} />
          </Popover>
        </InlineStack>} backAction={{
    content: 'Back',
    onAction: () => history('/product_collection')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa bộ sưu tập',
    onAction: () => handleDelete(entity?.collection_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => setShowEditModal(true)
  }]}>
      <div className="content-wrap">{Parser(entity?.collection_description ?? '')}</div>
      <br />
      <Divider />
      <br />
      <DisplayListOfProduct />
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    if (product_id) {
      createRelationship({
        collection_id: String(collection_id),
        product_id: String(product_id)
      });
    }
    setShowProductModal(false);
  }, [collection_id]);
  return <Capacity current_user_can={['manage_products', 'view_products', 'edit_products']} onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <ProductCollectionModal show={showEditModal} onClose={closeEditModal} current_collection_id={entity?.collection_id} />
      <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
      {loading ? <SkeletonLoading /> : actualPage}

      <br />
      <br />
      <br />
      <br />
      <br />
    </Capacity>;
}