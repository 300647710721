import { Checkbox, ContextualSaveBar, Form, Layout, Page, TextField, Card, BlockStack, Loading, Text, Box, FormLayout, Link, ChoiceList, Divider } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import helpers from 'helpers';
import { FormPrompt } from 'components/FormPrompt';
import DialogConfirm from 'components/dialogConfirm';
import QuickUploadImage from 'components/oneclick-upload-image';
import { Helmet } from 'react-helmet-async';
import TextFieldRichText from 'components/TextFieldRichText';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateGift, useDeleteGift, useMutateGetGift, useUpdateGift } from 'queries/gift.query';
import QuickSearchWallet from 'components/quickSearchWallet';
import QuickSearchVoucher from 'components/quickSearchVoucher';
import QuickSearchWalletCode from 'components/quickSearchWalletCode';
export default function RedeemEdit() {
  const {
    addNotification
  } = useNotification();
  const {
    mutate: getEntity,
    data: entity,
    isPending: loading
  } = useMutateGetGift();
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateGift();
  const {
    mutate: deleteEntity
  } = useDeleteGift();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess
  } = useCreateGift();
  let {
    gift_id
  } = useParams();
  const history = useNavigate();

  /**
   * Comment status change
   */

  const [gift_status, setRedeem_status] = useState(false);
  const handRedeemStatusChange = useCallback(newChecked => setRedeem_status(newChecked), []);

  /**
   * End comment status
   */

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDeletePost = useCallback(async slug => {
    const confirmed = await showDeleteConfirm('Xóa quà này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(slug);
      history(-1);
    }
  }, []);
  useEffect(() => {
    if (gift_id) {
      getEntity(gift_id);
    }
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('updated_successfully'));
      history('/gift/view/' + entity?.gift_id);
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) {
      addNotification('info', 'created_successfully');
      history('/gift');
    }
  }, [createSuccess]);
  useEffect(() => {
    if (entity) {
      setRedeem_status(Boolean(entity.gift_status));
    }
  }, [entity]);
  const useFields = {
    gift_title: useField<string>({
      value: entity?.gift_title ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.')]
    }),
    gift_description: useField<string>({
      value: entity?.gift_description ?? '',
      validates: [lengthLessThan(255, 'Không được dài quá 255 ký tự cho mô tả ngắn')]
    }),
    gift_content: useField<string>({
      value: entity?.gift_content ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(10000, 'Không được dài quá 10 000 ký tự')]
    }),
    gift_slug: useField<string>({
      value: entity?.gift_slug ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    gift_status: useField<string>({
      value: String(entity?.gift_status ?? '1'),
      validates: []
    }),
    gift_value: useField<number>({
      value: entity?.gift_value ?? 1,
      validates: [notEmpty('Trường này không được để trống.'), inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
        if (Number(inputVal) < 1) return 'Phải là một số lớn hơn 0.';
      }]
    }),
    gift_quantity: useField<string>({
      value: String(entity?.gift_quantity ?? '1'),
      validates: [notEmpty('Trường này không được để trống.'), inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
        if (Number(inputVal) < 1) return 'Phải là một số lớn hơn 0.';
      }]
    }),
    gift_thumbnail: useField<string>({
      value: entity?.gift_thumbnail,
      validates: []
    }),
    wallet_type_id: useField<string>({
      value: entity?.wallet_type_id,
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    gift_thumbnail_cache: useField<string>({
      value: entity?.gift_thumbnail_to_media ? entity?.gift_thumbnail_to_media?.media_thumbnail ? entity?.gift_thumbnail_to_media?.media_thumbnail['128x128'] : '' : '',
      validates: []
    }),
    gift_type: useField<string>({
      value: entity?.voucher_id ? 'voucher' : entity?.wallet_code_id ? 'wallet_code' : '',
      validates: []
    }),
    voucher_id: useField<string>({
      value: entity?.voucher_id,
      validates: []
    }),
    wallet_code_id: useField<string>({
      value: entity?.wallet_code_id,
      validates: []
    })
  };

  /** Slug tự động ... */
  useEffect(() => {
    if (gift_id) return;
    useFields.gift_slug.onChange(helpers.slugify(useFields.gift_title.value));
  }, [useFields.gift_title, gift_id]);
  const {
    fields,
    submit,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (values.gift_type === '') {
          values.voucher_id = null;
          values.wallet_code_id = null;
        }
        if (values.gift_type === 'wallet_code') {
          values.voucher_id = null;
        }
        if (values.gift_type === 'voucher') {
          values.wallet_code_id = null;
        }
        if (!gift_id) {
          // create new
          await createEntity({
            gift_title: values.gift_title,
            gift_value: helpers.parseNumeric(values.gift_value),
            gift_quantity: helpers.parseNumeric(values.gift_quantity),
            wallet_type_id: values.wallet_type_id,
            gift_content: values.gift_content,
            gift_description: values.gift_description,
            gift_slug: values.gift_slug,
            gift_status: Number(values.gift_status),
            gift_thumbnail: values.gift_thumbnail,
            voucher_id: values.voucher_id,
            wallet_code_id: values.wallet_code_id
          });
        } else {
          await updateEntity({
            gift_id: entity.gift_id,
            gift_title: values.gift_title,
            gift_value: helpers.parseNumeric(values.gift_value),
            gift_quantity: helpers.parseNumeric(values.gift_quantity),
            wallet_type_id: values.wallet_type_id,
            gift_content: values.gift_content,
            gift_description: values.gift_description,
            gift_slug: values.gift_slug,
            gift_status: Number(values.gift_status),
            gift_thumbnail: values.gift_thumbnail,
            voucher_id: values.voucher_id,
            wallet_code_id: values.wallet_code_id
          });
        }
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [showConfirmUnsavedForm, setShowConfirmUnsavedForm] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const Actual_page = <>
      {dirty ? <ContextualSaveBar alignContentFlush message="Thay đổi chưa được lưu" saveAction={{
      content: 'Lưu lại',
      loading: updating,
      onAction: submit
    }} discardAction={{
      content: 'Quay về',
      onAction: () => {
        setShowConfirmUnsavedForm(true);
      }
    }} /> : null}
      <Page title="Chỉnh sửa quà" backAction={{
      content: 'Gift list',
      onAction: () => {
        dirty ? setShowConfirmUnsavedForm(true) : history('/gift');
      }
    }}>
        <FormPrompt hasUnsavedChanges={dirty} />
        <DialogConfirm tone="critical" show={showConfirmUnsavedForm} onClose={e => {
        e && history('/gift');
        setShowConfirmUnsavedForm(false);
      }} title={'Rời khỏi trang mà chưa lưu thay đổi?'} content={'Rời khỏi trang này sẽ xóa tất cả các thay đổi chưa được lưu. Bấm hủy bỏ để tiếp tục chỉnh sửa.'} />

        <Layout>
          <Layout.Section>
            <Form onSubmit={submit}>
              <Card>
                <BlockStack gap="400">
                  <TextField autoComplete="off" label="Tiêu đề quà"
                // onFocus={submit}
                autoFocus {...fields.gift_title} />
                  <TextField autoComplete="off" label="Đường dẫn tĩnh cho quà" {...fields.gift_slug} />
                  <FormLayout.Group>
                    <TextField autoComplete="off" label="Giá trị quy đổi" {...fields.gift_value} value={helpers.formatNumberWithCommas(fields.gift_value.value)} onChange={v => fields.gift_value.onChange(helpers.parseNumeric(v))} />
                    <QuickSearchWallet title="Ví thanh toán" error={useFields.wallet_type_id.error} current_wallet_id={entity?.wallet_type_id} onClose={wallet_type_id => useFields.wallet_type_id.onChange(wallet_type_id)} />
                  </FormLayout.Group>

                  <ChoiceList title="Loại quà" choices={[{
                  label: 'Sản phẩm vật lý',
                  value: ''
                }, {
                  label: 'Hoặc là một voucher',
                  value: 'voucher',
                  renderChildren: isSeleted => {
                    return isSeleted && <QuickSearchVoucher title={' '} current_voucher_id={entity?.voucher_id} onClose={v => useFields.voucher_id.onChange(v.voucher_id)} />;
                  }
                }, {
                  label: 'Hay thưởng bằng mã Wallet Code',
                  value: 'wallet_code',
                  renderChildren: (isSeleted: boolean) => {
                    return isSeleted && <QuickSearchWalletCode title={''} current_wallet_code_id={entity?.wallet_code_id} onClose={v => useFields.wallet_code_id.onChange(v.wallet_code_id)} />;
                  }
                }]} selected={[useFields.gift_type.value]} onChange={v => useFields.gift_type.onChange(v[0])} />

                  {useFields.gift_type.value === 'voucher' && <>
                      <Divider />
                      <Text as="p" variant="bodySm" tone="success">
                        * Lưu ý: Voucher nên còn hạn sử dụng, vì nó sẽ được sao chép khi người dùng đổi quà.
                        <br />* Khi người dùng đổi quà là một voucher, bạn không cần phải duyệt qua yêu cầu này.
                      </Text>
                      <Divider />
                    </>}

                  <TextField autoComplete="off" requiredIndicator label="Số lượng quà" {...fields.gift_quantity} />

                  <TextField autoComplete="off" showCharacterCount maxLength={255} label="Mô tả ngắn" multiline={2} {...fields.gift_description} />

                  <TextFieldRichText label="Nội dung" requiredIndicator initialData={entity?.gift_content} onCallback={e => fields.gift_content.onChange(e)} error={fields.gift_content.error} />
                </BlockStack>
              </Card>
            </Form>
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <Box background="bg-fill" borderRadius="400" padding={'400'}>
              <BlockStack gap="400">
                <Text as="h4" variant="headingSm">
                  Ảnh đại diện
                </Text>

                <QuickUploadImage height={300} width={300} onSuccess={e => {
                useFields.gift_thumbnail.onChange(e.media_id);
                useFields.gift_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.gift_thumbnail_cache.value, 'https://placehold.co/512x512')} />
                <Text as="p" tone="subdued" variant="bodyXs">
                  Ảnh nên là kích thước theo tỉ lệ vuông 1x1
                </Text>

                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </BlockStack>
            </Box>
            <br />

            <Card>
              <Checkbox label="Cho phép hiển thị?" checked={gift_status} onChange={handRedeemStatusChange} />
              {gift_id && <Text as="p">
                  Nếu bạn muốn xóa món quà này, <Link onClick={() => handleDeletePost(gift_id)}>click vào đây</Link>
                </Text>}
            </Card>

            <br />
          </Layout.Section>
        </Layout>
      </Page>
      <br />
      <br />
    </>;
  return <>
      <Helmet>
        <title>Chỉnh sửa quà</title>
      </Helmet>
      {loading && <Loading />}
      {Actual_page}
    </>;
}