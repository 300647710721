import { ActionList, Avatar, Badge, Button, Card, InlineGrid, Link, Page, Popover } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IndexTable, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import { TypedWithdrawal_request, useGetWithdraws } from 'queries/withdraw.query';
export default function ListWithdraw() {
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetWithdraws(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedWithdrawal_request[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Các yêu cầu rút tiền', '/withdraw' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  function request_status_badge(request_status: number) {
    switch (request_status) {
      case -1:
        return <Badge>Từ chối</Badge>;
      case 0:
        return <Badge tone="info">Chưa duyệt</Badge>;
      default:
        return <Badge tone="success">Đã duyệt</Badge>;
    }
  }
  const rowMarkup = records?.map(({
    id,
    createdAt,
    updatedAt,
    amount,
    request_to_user,
    update_to_user,
    request_note,
    request_status
  }, index) => <IndexTable.Row id={`user_verified_` + id} position={index} key={`user_verified_` + index}>
      <IndexTable.Cell className="table_app_cellMinWidth">
        <Link onClick={() => history('/withdraw/' + id)}>
          <InlineGrid columns={['oneThird', 'twoThirds']} gap={'100'} alignItems="center">
            <Avatar size="lg" customer name={request_to_user?.display_name} initials={String(request_to_user?.display_name || 'R').charAt(0)} source={request_to_user.user_avatar ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + request_to_user.user_avatar : 'https://placehold.co/100x100'} />
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {request_to_user?.display_name}
            </Text>
          </InlineGrid>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{helpers.formatNumberWithCommas(amount)}</IndexTable.Cell>
      <IndexTable.Cell>{request_status_badge(request_status)}</IndexTable.Cell>
      <IndexTable.Cell>{update_to_user?.display_name}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(updatedAt)), 'HH:mm:ss DD-MM-YYYY')}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY')}</IndexTable.Cell>
    </IndexTable.Row>);
  return <Capacity current_user_can="manage_withdraw" onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <Page title="Danh sách yêu cầu rút tiền" subtitle="Các yêu cầu rút tiền của người dùng">
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Chưa duyệt',
          value: '0',
          field: 'request_status'
        }, {
          label: 'Từ chối',
          value: '-1',
          field: 'request_status'
        }, {
          label: 'Đã duyệt',
          value: '1',
          field: 'request_status'
        }]} sortField={[]} />
          <IndexTable resourceName={{
          singular: 'Các yêu cầu rút tiền',
          plural: 'Các yêu cầu rút tiền'
        }} itemCount={totalItems || 1} headings={[{
          title: 'Người gửi'
        }, {
          title: 'Số tiền'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Người duyệt'
        }, {
          title: 'Ngày duyệt'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {records?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </Capacity>;
}