import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Divider, Link, InlineStack, Page, SkeletonTabs, Text, Thumbnail, useIndexResourceState } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteProductBrand, useGetProductBrand } from 'queries/brand.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateProductToBrand, useDeleteProductToBrand, useGetProductToBrands } from 'queries/product_to_brand.query';
import ProductCategoryModalAddAndEdit from 'entities/product_brand/brand.modal';
import { useNotification } from 'context/NotificationContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PinIcon, PinFilledIcon } from '@shopify/polaris-icons';
import { usePinProductToTopBrand, useRemovePinProductToTopBrand, useBatchReorder } from 'queries/product_to_brand.query';
import { Tooltip, Button } from '@shopify/polaris';
export default function BrandDetail() {
  let {
    brand_id
  } = useParams();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductBrand(brand_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductBrand();
  const modalEntity = useRef(null);
  const {
    addNotification
  } = useNotification();

  /** relationship */
  const {
    data: productToBrandData,
    refetch: getRelationship
  } = useGetProductToBrands({
    limit: 100,
    brand_id: String(brand_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship,
    isSuccess: addProductToBrandSuccessful
  } = useCreateProductToBrand();
  const {
    mutateAsync: deleteRelationship
  } = useDeleteProductToBrand();
  const {
    mutateAsync: pinMe
  } = usePinProductToTopBrand();
  const {
    mutateAsync: unpinMe
  } = useRemovePinProductToTopBrand();
  const {
    mutate: batchReorder
  } = useBatchReorder();
  useEffect(() => {
    if (addProductToBrandSuccessful) addNotification('info', 'Thêm sản phẩm vào nhãn hiệu thành công.');
  }, [addProductToBrandSuccessful]);
  const [allRelationship, setAllRelationship] = useState([]);
  useEffect(() => {
    if (productToBrandData) {
      let {
        entities
      } = productToBrandData;
      setAllRelationship(entities);
    }
  }, [productToBrandData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    modalEntity.current = null;
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async brand_id => {
    const confirmed = await showDeleteConfirm('Xóa nhãn hiệu này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(brand_id);
      history('/product_brand');
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const resourceIDResolver = ({
    product
  }) => {
    return product.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(allRelationship ?? [], {
    resourceIDResolver
  });
  const [bulkDeleting, setBulkDeleting] = useState(false);
  const bulkDeleteActions = useCallback(async () => {
    setBulkDeleting(true);
    await helpers.sleep(1000);
    try {
      await deleteRelationship({
        brand_id: brand_id,
        product_id: helpers.ArrayToComma(selectedResources)
      });
    } catch (e) {}
    setBulkDeleting(false);
    clearSelection();
  }, [selectedResources]);
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    let cloneEntities = [...allRelationship];
    const [reorderedItem] = cloneEntities.splice(result.source.index, 1);
    cloneEntities.splice(result.destination.index, 0, reorderedItem);
    setAllRelationship(cloneEntities);
    let reorderData = {};
    let index = 0;
    for (let d of cloneEntities) {
      index++;
      reorderData = {
        ...reorderData,
        ...{
          [d.id]: index
        }
      };
    }
    batchReorder(reorderData);
  }, [allRelationship]);
  const DisplayListOfProduct = () => {
    if (allRelationship === null) return <SkeletonTabs />;
    if (Array.isArray(allRelationship) && allRelationship.length < 1) return <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào nhãn hiệu.
        </Text>;
    return <Card padding="0">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="Polaris-DataTable__ScrollContainer">
            <Droppable droppableId="table">
              {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                  <thead>
                    <tr>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">STT</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                        Tên sản phẩm
                      </th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                        Tình trạng
                      </th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                        Giá bán
                      </th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--header">
                        Giá gốc
                      </th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRelationship.map(({
                  product,
                  pin_to_top,
                  id
                }, index) => {
                  const {
                    product_id,
                    product_name,
                    product_price,
                    product_original_price,
                    product_status,
                    product_thumbnail_to_media
                  } = product;
                  return <Draggable key={id} draggableId={id} index={index}>
                          {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                <InlineStack align="start" blockAlign="center" gap={'400'}>
                                  <Thumbnail size="small" source={helpers.getThumbnailURI(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail : undefined, '128x128', '128x128')} alt={'product thumbnail: ' + product_name} />
                                  <Link onClick={() => history('/product/edit/' + product_id)}>{product_name}</Link>
                                </InlineStack>
                              </td>

                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{product_status}</td>

                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                {helpers.formatNumber(product_price, ',')}
                              </td>

                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                {helpers.formatNumber(product_original_price, ',')}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                {pin_to_top ? <Tooltip content="Bỏ ghim sản phẩm">
                                    <Button variant="plain" icon={PinFilledIcon} onClick={() => {
                            unpinMe(id);
                          }}></Button>
                                  </Tooltip> : <Tooltip content="Ghim sản phẩm lên đầu">
                                    <Button variant="plain" icon={PinIcon} onClick={() => {
                            pinMe(id);
                          }}></Button>
                                  </Tooltip>}
                              </td>
                            </tr>}
                        </Draggable>;
                })}
                    {provided.placeholder}
                  </tbody>
                </table>}
            </Droppable>
          </div>
        </DragDropContext>
      </Card>;
  };
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.brand_name} subtitle={entity.brand_excerpt} backAction={{
    content: 'Back',
    onAction: () => history('/product_brand')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa nhãn hiệu',
    onAction: () => handleDelete(entity?.brand_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => {
      modalEntity.current = entity;
      setShowEditModal(true);
    }
  }]}>
      <div className="content-wrap">{Parser(entity?.brand_description ?? '')}</div>
      <br />
      <Divider />
      <br />
      <DisplayListOfProduct />
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    try {
      if (product_id) {
        await createRelationship({
          brand_id: String(brand_id),
          product_id: String(product_id)
        });
      }
    } catch (e) {}
    setShowProductModal(false);
  }, [brand_id]);
  return <Capacity current_user_can={['manage_products', 'view_products', 'edit_products']} onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <ProductCategoryModalAddAndEdit show={showEditModal} onClose={closeEditModal} entity={modalEntity.current} />
      <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
      {loading ? <SkeletonLoading /> : actualPage}

      <br />
      <br />
      <br />
      <br />
      <br />
    </Capacity>;
}