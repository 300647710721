import { BlockStack, Box, Card, InlineStack, SkeletonBodyText, SkeletonDisplayText, Text } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import __helpers from 'helpers/index';
import Template404 from 'layout/404';
import { TypedCustomerWallet, useGetCustomerWallets } from 'queries/customer.query';
import React, { useEffect, useState } from 'react';
export default function CustomerWalletSumarize({
  customer_id
}: {
  customer_id: string;
}) {
  const {
    data,
    isFetching,
    refetch
  } = useGetCustomerWallets(customer_id);
  const [entities, setEntities] = useState<TypedCustomerWallet[]>([]);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
    }
  }, [data]);
  useEffect(() => {
    refetch();
  }, [customer_id]);
  return <Capacity current_user_can={'view_customer_wallet'} onFail={<Template404 />}>
      <InlineStack align="start" blockAlign="center" gap="200">
        {isFetching && <>
            {['1', '2', '3'].map((element, index) => {
          return <div style={{
            minWidth: '120px'
          }} key={index}>
                  <BlockStack gap="200">
                    <SkeletonBodyText lines={1} />
                    <SkeletonDisplayText />
                  </BlockStack>
                </div>;
        })}
          </>}

        {Array.isArray(entities) && entities.map((walletElement, index) => {
        return <BlockStack gap="200" key={index}>
                <Box paddingBlock={'100'} paddingInline={'400'} background="bg-fill">
                  <Text as="p" tone="subdued">
                    {walletElement?.wallet_type?.wallet_name}
                  </Text>
                  <Text as="p" fontWeight="bold">
                    {__helpers.formatNumber(Number(walletElement?.balance || 0))} {walletElement?.wallet_type?.wallet_sign}
                  </Text>
                </Box>
              </BlockStack>;
      })}
      </InlineStack>
    </Capacity>;
}