import { DataTable, Page, Text, Link, Popover, Button, TextField, Card } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { ArchiveIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { ___ } from 'languages/index';
import { useCreateBackup, useDeleteBackup, useGetBackups } from 'queries/backup.query';
import { useNotification } from 'context/NotificationContext';
export default function BackUp() {
  const {
    addNotification
  } = useNotification();
  const {
    data
  } = useGetBackups();
  const {
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: createSuccessfully
  } = useCreateBackup();
  const {
    mutateAsync: deleteEntity,
    isSuccess: deleteSuccesffully
  } = useDeleteBackup();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (createSuccessfully) {
      addNotification('info', 'Đã tạo bản backup thành công.');
    }
  }, [createSuccessfully]);
  useEffect(() => {
    if (deleteSuccesffully) {
      addNotification('info', 'Đã xóa bản backup thành công.');
    }
  }, [deleteSuccesffully]);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  const deleteFile = useCallback(async (filename: string) => {
    await deleteEntity(filename);
  }, []);
  const startBackingup = useCallback(async () => {
    try {
      await createEntity();
    } catch (e) {
      console.error(e);
    }
  }, []);
  const [textInLog, setTextInLog] = useState<string>('');
  const [showA, setShowA] = useState<string>(null);
  function showLog(file_name: string, logContent: string) {
    setShowA(file_name);
    setTextInLog(logContent);
  }
  function closeLog() {
    setShowA(null);
    setTextInLog('');
  }
  const items = item => {
    const {
      file_name,
      birthtime,
      size,
      file_log
    } = item;
    return [file_log ? <Popover active={showA === file_name} activator={<Button variant="plain" onClick={() => showLog(file_name, file_log)}>
              {file_name}
            </Button>} onClose={() => closeLog()} sectioned>
          <TextField value={textInLog} label="Log: " multiline={3} onChange={null} autoComplete="off" />
        </Popover> : file_name, helpers.bytesToSize(size / 1028), dateandtime.format(new Date(birthtime), 'HH:mm:ss DD-MM-YYYY'), <Link url={process.env.REACT_APP_BACKEND_URL + '/utils/backup/' + file_name} target="_blank">
        Tải xuống
      </Link>, <Link onClick={() => deleteFile(file_name)}> Xóa bỏ</Link>];
  };
  return <>
      <Page narrowWidth title="Backup hệ thống" subtitle="Để đảm bảo an toàn, bạn không nên giao một bản backup cho người khác" secondaryActions={[{
      content: 'Bắt đầu backup',
      icon: ArchiveIcon,
      loading: updating,
      onAction: startBackingup
    }]}>
        <Card padding="0">
          <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['Tên phiên bản', 'kích thước', 'Thời gian tạo', '', '']} rows={entities?.map(items)} hasZebraStripingOnData increasedTableDensity footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>
      </Page>
    </>;
}