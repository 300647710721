import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { separate_route as SeparateRoute, public_route, private_route } from './config/router-define';
import AppFrame from 'layout/appFrame';
import Theme404 from 'layout/404';
import ProtectedRoute from 'ProtectedRoute';
import useRefreshTokenHelper from 'components/useRefreshTokenHelper';
import { useCallback } from 'react';
import { FloatingBannerProvider } from 'context/FloatingBannerProvider';
const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');
export function App() {
  useRefreshTokenHelper();
  const delayloadingScreen = useCallback(async () => {
    document.getElementById('root').style.display = 'block';
    document.getElementById('before_load').style.display = 'none';
    document.getElementById('before_load').style.width = '0';
    document.getElementById('before_load').style.height = '0';
  }, []);
  delayloadingScreen();
  return <BrowserRouter basename={baseHref}>
      <Routes>
        {SeparateRoute.map((MainRouter, index) => <Route key={index} path={MainRouter.path} element={<MainRouter.Entity />} />)}
        {public_route.map((MainRouter, index) => <Route key={`public_${index}`} path={MainRouter.path} element={<FloatingBannerProvider>
                <AppFrame>
                  <MainRouter.Entity />
                </AppFrame>
              </FloatingBannerProvider>} />)}
        {private_route.map((MainRouter, index) => <Route key={`private_${index}`} path={MainRouter.path} element={<ProtectedRoute>
                <FloatingBannerProvider>
                  <AppFrame>
                    <MainRouter.Entity />
                  </AppFrame>
                </FloatingBannerProvider>
              </ProtectedRoute>} />)}
        <Route key={99999} path="*" element={<Theme404 />} />
      </Routes>
    </BrowserRouter>;
}
export default App;