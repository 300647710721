import { useAuth } from 'context/AuthContext';
import __helpers from 'helpers/index';
import React, { useEffect, useState } from 'react';

/**
 * Check capacity of a current user.
 * @current_user_can - A string or an array of strings defining the required capacities.
 * @extra_condition - A boolean to allow additional conditions.
 * @onFail - Content to render when access is denied.
 * @children - Content to render when access is granted.
 */
export default function Capacity({
  current_user_can,
  extra_condition = true,
  onFail,
  children
}: {
  current_user_can: string | string[];
  extra_condition?: boolean;
  onFail?: React.ReactNode;
  children: React.ReactNode;
}) {
  const {
    user: data
  } = useAuth();
  const [allow, setAllow] = useState<boolean | null>(null);
  useEffect(() => {
    if (!data) return;
    const {
      capacities,
      user_role
    } = data;
    const requiredCapacities = Array.isArray(current_user_can) ? current_user_can : [current_user_can];
    let isAllowed = false;

    // Direct permissions for 'super_admin' and 'admin'
    if (['super_admin', 'admin'].includes(user_role)) {
      isAllowed = true;
    } else {
      // Check user_role against required capacities
      if (requiredCapacities.includes(user_role)) {
        isAllowed = true;
      } else if (__helpers.isObject(capacities)) {
        // Check specific capacities
        isAllowed = Object.values(capacities).some(value => Array.isArray(value) && value.some((cap: string) => requiredCapacities.includes(cap)));
      }
    }
    setAllow(isAllowed);
  }, [data, current_user_can]);
  if (allow === null) return null; // Render nothing while determining permissions

  return <>{allow && extra_condition ? children : onFail || null}</>;
}