import { Page, Badge, DataTable, Link, Popover, BlockStack, Loading, Tag, InlineStack, Card, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import { useAddAssignee, useUpdateContactform, useGetContactforms } from 'queries/contactform.query';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import QuickSearchUser from 'components/quickSearchUser';
import CustomerDetailPopup from '../../components/customer.detail.popup';
import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import __ from 'languages/index';
export default function ContactFormList() {
  const {
    mutateAsync: addAssignee,
    isPending: addAssigneeLoading
  } = useAddAssignee();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateContactform();
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isFetching: loading
  } = useGetContactforms(mainQuery);
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Contactform', '/contactform' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  useEffect(() => {
    if (updateSuccess === true) {
      reduceRequestMemo(mainQuery);
    }
  }, [updateSuccess]);
  function contactformCondition(_status: number) {
    switch (_status) {
      case 9:
        return <Badge progress="incomplete">Hủy</Badge>;
      case 8:
        return <Badge progress="incomplete" tone="attention">
            Đang xử lý
          </Badge>;
      case 7:
        return <Badge tone="success" progress="complete">
            Đã xử lý xong
          </Badge>;
      default:
        return <>
            <Badge></Badge>
            <Badge></Badge>
          </>;
    }
  }

  /**
   * For button only ...
   */
  const [showPophoverStatus, setShowPophoverStatus] = useState(null);
  const togglePophoverStatusCallback = useCallback(async (contactform_id: any) => {
    await helpers.sleep(100);
    setShowPophoverStatus(contactform_id);
  }, []);
  const onClosePophoverStatusCallback = useCallback(() => {
    setShowPophoverStatus(null);
  }, []);
  const changeCampaignStatus = useCallback((contactform_id: any, new_status: number) => {
    onClosePophoverStatusCallback();
    updateCallback(contactform_id, new_status);
  }, []);
  const updateCallback = useCallback(async (contactform_id: any, new_status: number) => {
    await updateEntity({
      contactform_id: contactform_id,
      contactform_status: Number(new_status)
    });
  }, []);
  const quickupdateButton = (contactform_id, contactform_status) => {
    return <Popover key={contactform_id} active={showPophoverStatus === contactform_id} activator={<Link onClick={() => togglePophoverStatusCallback(contactform_id)}>{contactformCondition(contactform_status)}</Link>} onClose={() => onClosePophoverStatusCallback()} ariaHaspopup={false} sectioned>
        <BlockStack gap={'200'}>
          <Link removeUnderline onClick={() => changeCampaignStatus(contactform_id, 7)}>
            {contactformCondition(7)}
          </Link>
          <Link removeUnderline onClick={() => changeCampaignStatus(contactform_id, 8)}>
            {contactformCondition(8)}
          </Link>
          <Link removeUnderline onClick={() => changeCampaignStatus(contactform_id, 9)}>
            {contactformCondition(9)}
          </Link>
        </BlockStack>
      </Popover>;
  };
  const [showAddnewUser, setShowAddnewUser] = useState(null);
  const onCloseCustomerModal = useCallback(async (user_id: string, contactform_id: string) => {
    if (user_id) {
      await addAssignee({
        contactform_id: contactform_id,
        user_id: String(user_id)
      });
      reduceRequestMemo(mainQuery);
    }
    setShowAddnewUser(null);
  }, [mainQuery]);
  const [showCustomerPopup, setShowCustomerPopup] = useState(null);
  return <>
      <Helmet>
        <title>Contact form</title>
      </Helmet>
      {updating && <Loading />}
      <CustomerDetailPopup onClose={() => setShowCustomerPopup(0)} show={showCustomerPopup} />
      <Page title="Contact Form" fullWidth>
        <Card padding="0">
          <SimpleFilter loading={loading} options={[{
          label: 'Ban chung',
          value: 'general',
          field: 'contactform_category'
        }, {
          label: 'Kế toán',
          value: 'accountant',
          field: 'contactform_category'
        }, {
          label: 'Giám đốc',
          value: 'manager',
          field: 'contactform_category'
        }, {
          label: 'Phòng sale',
          value: 'sale',
          field: 'contactform_category'
        }]} onCallback={filterCallback} sortField={[{
          label: 'Tình trạng xử lý',
          field: 'contactform_status'
        }]} />
          <DataTable increasedTableDensity columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']} headings={['Phòng ban', 'Tựa đề', 'Người gửi', 'Nhân viên xử lý', 'Tình trạng xử lý', 'Ngày tạo']} rows={entities?.map(x => {
          return [__(`contact_${x.contactform_category}_department`), <Link url={'/contactform/view/' + x.contactform_id}>{helpers.trimMiddleString(x.contactform_title, 30, 10)}</Link>, x.user_id ? <Link onClick={() => setShowCustomerPopup(x.user_id)}>{x?.user?.display_name || x.user?.user_login}</Link> : `${x.contactform_name || ''}/ ${x.contactform_email || ''}/ ${x.contactform_numberphone || ''}`, <InlineStack gap={'200'} align="start" blockAlign="center">
                    {Array.isArray(x.user_to_contactform) && x.user_to_contactform.length > 0 ? x.user_to_contactform.map((e, index) => {
              return <Tag key={index + 'taglist'}>{e?.assignee?.display_name}</Tag>;
            }) : <Popover active={showAddnewUser === x.contactform_id} activator={<Link onClick={() => setShowAddnewUser(x.contactform_id)}>
                            <>
                              <Badge></Badge>
                              <Badge></Badge>
                            </>
                          </Link>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(null)}>
                        <Card>
                          <QuickSearchUser title="" user_role="sale,sale_admin,admin" onClose={(userData: any) => {
                  let __newUser = userData.user_id;
                  onCloseCustomerModal(__newUser, x.contactform_id);
                  setShowAddnewUser(null);
                }} />
                        </Card>
                      </Popover>}
                  </InlineStack>, quickupdateButton(x.contactform_id, x.contactform_status), dateandtime.format(new Date(Number(x?.createdAt)), 'HH:mm:ss DD-MM-YYYY')];
        }) || []} />
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
      <br />
      <br />
      <br />
      <br />
    </>;
}