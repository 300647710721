import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";

export type TypedProductVariantCommission = {
    commission_level: number,
    commission_value: number,
    commission_method: string,
}
export type TypedProductVariantCommissionAdvance = {
    variant_id?: string,
    commission_parent_index: number,
    user_role: string,
    commission_value: number,
}


export interface TypedProduct_variant {
    variant_id?: string;
    product_id?: string;
    variant_thumbnail?: string;
    variant_name?: string;
    variant_slug?: string; // read only
    variant_excerpt?: string;
    variant_sku?: string;
    variant_barcode?: string;
    variant_price?: number;
    variant_original_price?: number;
    variant_status?: number;

    variant_has_commission?: number, // có commission hay không?
    variant_has_rebate?: number, // có chiết khấu không?
    variant_has_tiered_rebate?: number, // có chiết khấu phân tầng hay không?



    hash?: string; // read only ...
    createdAt?: string;

    variant_thumbnail_to_media?: TypedMedia | null

    /** doanh thu đội nhóm */
    product_variant_group_income_value?: number;
    product_variant_group_income?: {
        id: string,
        variant_id: string,
        group_income_value: number,
    },



    commission?: TypedProductVariantCommission[], // for create or update
    product_variant_commission?: TypedProductVariantCommission[], // for call out


    commission_advance?: TypedProductVariantCommissionAdvance[], // for create or update
    product_variant_commission_advance?: TypedProductVariantCommissionAdvance[], // for call out

    rebate?: {
        rebate_value: number,
        rebate_method: string,
        user_role: string,
    }[];// for create or update
    product_variant_rebate?: {
        rebate_value?: number,
        rebate_method?: string,
        user_role: string
    }[];

    tiered_rebate?: any, // for save
    product_variant_tiered_rebate?: { // for callout
        rebate_value: number,
        rebate_method: string,
        user_role: "agency" | "collaborator" | "customer"
    }[]


}


type IQuery = TypedProduct_variant & IQueryParams;



/*
* List of all product_variant
*/
export function useGetProductVariants(product_id: string) {
    return useQuery({
        queryKey: ["product_variant/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct_variant[]>(`/product_variant/${product_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}



export function useGetProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/fetch_entity'],
        mutationFn: (product_variant_id: string) => axios.get<TypedProduct_variant>(`/product_variant/view/${product_variant_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/update_entity'],
        mutationFn: ({ variant_id, ...rest }: TypedProduct_variant) => axios.patch<any>(`product_variant/${variant_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}


export function useCreateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/create_entity'],
        mutationFn: (entity: TypedProduct_variant) => axios.post<any>(`/product_variant`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`product_variant/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}



/** Tự động tạo hàng loạt variant cho nó nhanh ... */
export function useBulkCreateProductVariant() {
    return useMutation({
        mutationKey: ['product_variant/bulk_create_entity'],
        mutationFn: (product_id: string) => axios.post<any>(`product_variant/bulk_create/${product_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_variant/fetch_entity_list'] });
        }
    });
}



