import React, { createContext, useContext, useState, ReactNode, ReactElement, useCallback, useEffect } from 'react';
import 'media/css/floatingBanner.scss';
import { Button, Collapsible, Icon, InlineStack } from '@shopify/polaris';
import { XIcon, InfoIcon } from '@shopify/polaris-icons';
const notificationWav = require('media/audio/notification.wav');
const audio = new Audio(notificationWav); // Path to your audio file
audio.preload = 'auto';
audio.pause();
let window_clicked: boolean = false;
window.addEventListener('click', () => {
  window_clicked = true;
});
window.addEventListener('touchstart', () => {
  window_clicked = true;
});
interface FloatingBannerContextProps {
  showBanner: (options: ShowBannerOptions) => void;
}
interface ShowBannerOptions {
  id?: string; // Optional ID for the banner
  title?: string;
  icon?: any;
  content?: ReactElement;
  duration?: number; // Duration to auto-remove the banner (in ms)
}
interface BannerData {
  id: string; // Unique ID for the banner
  title: string;
  icon: any;
  content: ReactElement | null;
  isCollapsed: boolean; // State for collapsible animation
  paused: boolean; // Indicates if the banner is paused
  timerId?: NodeJS.Timeout; // Reference to the timeout for auto-remove
}
const FloatingBannerContext = createContext<FloatingBannerContextProps | undefined>(undefined);
export const FloatingBannerProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [banners, setBanners] = useState<BannerData[]>([]);
  const playAudio = useCallback(() => {
    try {
      if (window_clicked) audio.play().then().catch(() => {});
    } catch (e) {}
  }, []);
  const showBanner = useCallback(({
    id,
    title = 'Tựa đề',
    icon = InfoIcon,
    content = null,
    duration = 5000
  }: ShowBannerOptions) => {
    const newBanner: BannerData = {
      id: id || `${Date.now()}-${Math.random()}`,
      title,
      icon,
      content,
      isCollapsed: false,
      paused: false
    };
    playAudio();
    setBanners(prev => [...prev, newBanner]);
    const timerId = setTimeout(() => {
      closeFloatingBanner(newBanner.id, true);
    }, duration);
    setBanners(prev => prev.map(banner => banner.id === newBanner.id ? {
      ...banner,
      timerId
    } : banner));
  }, []);
  const closeFloatingBanner = useCallback((banner_id: string, triggerCollapse: boolean = false) => {
    if (triggerCollapse) {
      setBanners(prev => prev.map(banner => banner.id === banner_id ? {
        ...banner,
        isCollapsed: true
      } : banner));
      setTimeout(() => {
        setBanners(prev => prev.filter(banner => banner.id !== banner_id));
      }, 500);
    } else {
      setBanners(prev => prev.filter(banner => banner.id !== banner_id));
    }
  }, []);
  const pauseBanner = useCallback((banner_id: string) => {
    setBanners(prev => prev.map(banner => {
      if (banner.id === banner_id && banner.timerId) {
        clearTimeout(banner.timerId); // Cancel the timer when paused
      }
      return {
        ...banner,
        paused: true
      };
    }));
  }, []);
  const resumeBanner = useCallback((banner_id: string, duration: number) => {
    setBanners(prev => prev.map(banner => {
      if (banner.id === banner_id && banner.paused) {
        const timerId = setTimeout(() => {
          closeFloatingBanner(banner_id, true);
        }, duration);
        return {
          ...banner,
          paused: false,
          timerId
        };
      }
      return banner;
    }));
  }, []);
  return <FloatingBannerContext.Provider value={{
    showBanner
  }}>
      {children}
      <div className="floating_banners_container">
        {banners.map(banner => <div key={banner.id} className="floating_banner" onMouseEnter={() => pauseBanner(banner.id)} // Pause on hover
      onMouseLeave={() => resumeBanner(banner.id, 5000)} // Resume on mouse leave
      >
            <div className="floating_wrap">
              <div className="floating_header">
                <InlineStack blockAlign="center" align="space-between">
                  <Button variant="plain" icon={banner.icon}>
                    {banner.title}
                  </Button>
                  <Button icon={XIcon} variant="plain" onClick={() => closeFloatingBanner(banner.id, true)}></Button>
                </InlineStack>
              </div>
              <Collapsible open={!banner.isCollapsed} id={`collapsible-${banner.id}`} transition={{
            duration: '100ms',
            timingFunction: 'ease-in-out'
          }} expandOnPrint>
                <div className="floating_banner_content">
                  <br />
                  {banner.content}
                  <br />
                </div>
              </Collapsible>
            </div>
          </div>)}
      </div>
    </FloatingBannerContext.Provider>;
};
export const useFloatingBanner = (): FloatingBannerContextProps => {
  const context = useContext(FloatingBannerContext);
  if (!context) {
    throw new Error('useFloatingBanner must be used within a FloatingBannerProvider');
  }
  return context;
};