import { useGetSettings } from 'queries/setting.query';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

// Định nghĩa kiểu cho từng setting
type Setting = Record<string, any> | null;

// Định nghĩa kiểu cho Context
interface SettingsContextType {
  settings: Setting | null;
  loading: boolean;
}

// Context mặc định
const SettingsContext = createContext<SettingsContextType>({
  settings: null,
  loading: true
});

// Provider component
interface SettingsProviderProps {
  children: ReactNode;
}
export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children
}) => {
  const [settings, setSettings] = useState<Setting | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    data: entities,
    isLoading: loadingSetting,
    isSuccess
  } = useGetSettings({
    limit: 1000
  });
  useEffect(() => {
    if (isSuccess && entities) {
      setSettings(entities);
    }
    if (!loadingSetting) {
      setLoading(false);
    }
  }, [entities, isSuccess, loadingSetting]);
  return <SettingsContext.Provider value={{
    settings,
    loading
  }}>{children}</SettingsContext.Provider>;
};

// Custom hook để sử dụng Context
export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};