import { Autocomplete, Avatar, Button, Icon, Select } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import __ from 'languages/index';
import { SearchIcon, XIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { TypedUser } from 'interface/user.model';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import CustomerNew from 'entities/customer/customer.new';
import { useSearchUser } from 'queries/user.query';
import { useUserRoleContext } from 'context/UserRoleContext';
type TypedSearchUser = {
  current_user_id?: bigint | string /** vô dụng, không hoạt động ... */;
  onClose?: (val: TypedUser) => void;
  title: string;
  placeholder?: string;
  /** "user" | "customer" | "agency" | "collaborator" | "sale" | "sale_admin" | "admin" | "visitor" */
  user_role?: string;
  disabled?: boolean;
  support_create_new?: boolean;
  support_filter_user_role?: boolean;
};

/**
 *
 * @param current_user_id bigint
 * @param  onClose callback with bigint userid
 * @returns
 */
export default function QuickSearchUser({
  disabled,
  current_user_id = null,
  user_role = null,
  onClose,
  title,
  placeholder = 'Tìm một người dùng',
  support_create_new = false,
  support_filter_user_role = false
}: TypedSearchUser) {
  /**!SECTION
   * Select người handler
   */

  const {
    mutateAsync: searchUser,
    isPending: userLoading
  } = useSearchUser();

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();
  /** Hết code lấy user role ra ... */

  const initialQuery = {
    query: '',
    user_role: undefined,
    sort: 'createdAt:desc',
    limit: 5
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  useEffect(() => {
    if (current_user_id) {
      setMainQuery(old => ({
        ...old,
        ...{
          user_id: current_user_id
        }
      }));
    } else {
      setMainQuery(old => {
        let newQuery = {
          ...old
        };
        // @ts-ignore
        if (newQuery?.user_id) {
          // @ts-ignore
          delete newQuery.user_id;
        }
        return newQuery;
      });
    }
  }, [current_user_id]);

  /**
   * User list
   */
  const [userList, setUserList] = useState<any[]>([]);
  const onQueryToServer = useCallback(async queryString => {
    try {
      let data = await searchUser(queryString);
      // @ts-ignore
      setUserList(data);
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (mainQuery) {
      onQueryToServer(mainQuery);
    }
  }, [mainQuery]);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  const [userRoleSelect, setUserRoleSelect] = useState<string>(user_role);
  const clearForm = useCallback(() => {
    setUserQuickSearchText('');
    setUserSelected([]);
    onClose?.call(this, {
      user_id: ''
    }); // clear thì phải bắn lại ...
  }, []);
  const onQueryToServerDebound = useCallback(async (keyword, user_role) => {
    setMainQuery(old => ({
      ...old,
      ...{
        query: keyword,
        user_role: user_role
      }
    }));
  }, []);
  useEffect(() => {
    onQueryToServerDebound('', userRoleSelect);
  }, [userRoleSelect]);
  useEffect(() => {
    let innerUser = [];
    if (userList) for (let user of userList) {
      let display_name = user.display_name || user.user_email || user.user_phonenumber || user.user_login;
      let user_role = user.user_role;
      innerUser.push({
        value: user.user_id,
        label: `${display_name} - (${user_role})`,
        media: <Avatar source={user.user_avatar} name={display_name} initials={display_name[0]} />
      });
      if (current_user_id && String(user.user_id) === String(current_user_id)) {
        // convert sang String cho an toàn ...
        setUserQuickSearchText(user.display_name);
      }
    }
    setUserListForQuickOption(innerUser);
  }, [userList, current_user_id]);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce((_value: string, userRoleSelect: string) => {
      onQueryToServerDebound?.call(this, _value, userRoleSelect);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value, userRoleSelect);
  }, [userRoleSelect]);
  const findUserSelectedInEntities = useCallback(user_id => {
    if (Array.isArray(userList)) {
      let a = userList.filter(a => a.user_id === user_id);
      return a[0] || null;
    }
  }, [userList]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    // @ts-ignore
    onClose?.call(this, findUserSelectedInEntities(selected[0]));
  }, [userListForQuickOption]);
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const closeNewCustomerModal = useCallback((userData?: TypedUser) => {
    setShowNewCustomerModal(false);
    if (userData) {
      setUserQuickSearchText(String(userData.display_name || userData.user_email || userData.user_login));
      setUserSelected([String(userData.user_id)]);
      onClose?.call(this, userData); // ...
    }
  }, []);
  const textFieldSelectUser = <>
      <Autocomplete.TextField onChange={updateUserText} label={title} disabled={disabled} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder={placeholder} autoComplete="off" connectedRight={<Button onClick={clearForm} icon={XIcon}></Button>} />
    </>;
  const handleSelectChange = useCallback((value: string) => {
    setUserRoleSelect(value);
  }, []);
  const FilterCustomerType = useCallback(() => {
    return <>
        <Select label="Lọc vai trò:" labelInline options={[...[{
        label: 'Tất cả',
        value: ''
      }], ...allRoles]} onChange={handleSelectChange} value={userRoleSelect || undefined} />
        <br />
      </>;
  }, [userRoleSelect]);
  return <>
      {support_filter_user_role && <FilterCustomerType />}
      {support_create_new && <CustomerNew onClose={closeNewCustomerModal} show={showNewCustomerModal} />}
      <Autocomplete actionBefore={support_create_new && {
      accessibilityLabel: 'Tạo người dùng mới',
      badge: {
        tone: 'new',
        content: 'New!'
      },
      content: 'Tạo người dùng mới',
      ellipsis: true,
      icon: PlusCircleIcon,
      onAction: () => {
        setShowNewCustomerModal(true);
        return false;
      }
    }} options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={userLoading} id="quickSearchUserModalPopover" />
    </>;
}