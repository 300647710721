import { DataTable, Page, Text, Link, Icon, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { ArchiveIcon } from '@shopify/polaris-icons';
import StockCreateModal from './stock.create.update';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { TypedStock, useDeleteStock, useGetStocks } from 'queries/stock.query';
import { useNavigate } from 'react-router-dom';
import { StarIcon } from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function StockList() {
  const {
    mutate: deleteEntity
  } = useDeleteStock();
  const history = useNavigate();
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async stock_id => {
    const confirmed = await showDeleteConfirm('Xóa kho hàng này?', 'Toàn bộ thông tin liên quan tới hàng hóa, bao gồm số lượng sản phẩm, sản phẩm, đơn hàng, đơn vị vận chuyển... có thể bị xóa bỏ cùng.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(stock_id);
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data: entities,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetStocks(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedStock[]>([]);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setRecords(body);
      setTotalItems(totalItems);
    }
  }, [entities]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Stock', '/stock' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const [currentID, setCurrentID] = useState(null);
  const items = (item: TypedStock) => {
    const {
      stock_id,
      stock_name,
      stock_address,
      stock_code,
      manager,
      hotline,
      createdAt,
      is_default
    } = item;
    return [is_default === 1 ? <Icon source={StarIcon} /> : '', <Link onClick={() => history(`/stock/view/${stock_id}`)} removeUnderline>
        <Text as="h3" fontWeight="bold" tone="caution">
          {stock_name}
        </Text>
        <Text as="p" variant="bodyXs" tone="disabled">
          {stock_code}
        </Text>
      </Link>, stock_address, manager, hotline, dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY'), <Link onClick={() => {
      setCurrentID(stock_id);
      setShowModal(true);
    }}>
        Chỉnh sửa
      </Link>, <Link onClick={() => handleDelete(stock_id)}> Xóa bỏ</Link>];
  };
  return <>
      <Helmet>
        <title>Danh sách kho hàng</title>
      </Helmet>

      <StockCreateModal onClose={() => {
      setShowModal(false);
      setCurrentID(null);
    }} current_stock_id={currentID} show={showModal} />

      <Page title="Quản lý kho" secondaryActions={[{
      content: 'Tạo kho hàng',
      icon: ArchiveIcon,
      onAction: () => {
        setCurrentID(null);
        setShowModal(true);
      }
    }]}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={() => {}} options={[]} loading={false} sortField={[]} />
          <DataTable verticalAlign="middle" hideScrollIndicator columnContentTypes={['text', 'text', 'text', 'numeric', 'text', 'text', 'text', 'text', 'text']} headings={['', 'Tên', 'Địa chỉ', 'Người quản lý', 'hotline', 'Tạo lúc', '', '']} rows={records?.map(items)} footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} hasZebraStripingOnData increasedTableDensity />
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}