import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BlockStack, Box, Card, Text, InlineStack, Link, Page, SkeletonTabs, Divider } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
import 'media/css/group_income.scss';
import { GroupIncomeOrder, useGetGroupIncome, useGetGroupIncomeSumarize } from 'queries/group_income.query';
import dateandtime from 'date-and-time';
import __helpers from 'helpers/index';
import For from 'components/For';
import __ from 'languages/index';
export default function GroupIncome() {
  const {
    data,
    refetch,
    isLoading
  } = useGetGroupIncome({
    limit: 4
  });
  const {
    data: sumarizeGroupIncome,
    isLoading: loadingSumarize
  } = useGetGroupIncomeSumarize();
  const [entities, setEntities] = useState<GroupIncomeOrder[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  const groups = useRef('');
  const group_income_loop = useCallback((element: GroupIncomeOrder, index) => {
    const {
      createdAt,
      group_income_order_id,
      total_group_income_value,
      order_id,
      product_id,
      customer_id,
      customer,
      product,
      quantity
    } = element;
    let datetimeCreate = new Date(Number(createdAt));
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023

    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <React.Fragment key={`${index}_comment_body`}>
        {A && <div className="step-item">{M}</div>}
        <div className="card" id={'comment_' + group_income_order_id}>
          <div className="timeline_handler"></div>
          <div className="time">
            <span className="hour">{dateandtime.format(datetimeCreate, 'HH:mm')}</span>
            <div className="author">
              <Link url={'/customer/view/' + customer_id}>@{customer?.display_name}</Link>
            </div>
          </div>
          <div className="info">
            <h3 className="title">+{__helpers.formatNumber(total_group_income_value)} kim cương</h3>
            <Text as="p" variant="bodyMd">
              Đơn <Link url={`/order/view/${order_id}`}>#{order_id}</Link>, Mua {product?.product_name} x {quantity}
            </Text>
          </div>
        </div>
      </React.Fragment>;
  }, []);
  return <>
      <Helmet>
        <title>Doanh thu đội nhóm</title>
      </Helmet>

      <div id="group_income_hero_bar">
        <h1>Doanh thu đội nhóm</h1>
      </div>

      <Page fullWidth>
        <div id="group_income_page">
          <div id="group_income_sumarize">
            {loadingSumarize ? <SkeletonTabs /> : <>
                <InlineStack align="start" blockAlign="center" gap={'400'}>
                  <BlockStack>
                    <Text as="p" variant="bodyMd">
                      TỔNG SỐ ĐƠN
                    </Text>
                    <Text as="h2" variant="headingLg">
                      {sumarizeGroupIncome?._count.order_id}
                    </Text>
                  </BlockStack>
                  <BlockStack>
                    <Text as="p" variant="bodyMd">
                      TỔNG GIÁ TRỊ
                    </Text>
                    <Text as="h2" variant="headingLg">
                      {__helpers.formatNumber(sumarizeGroupIncome?._sum?.total_group_income_value || 0)}
                    </Text>
                  </BlockStack>
                  <BlockStack>
                    <Text as="p" variant="bodyMd">
                      TỔNG ĐƠN VỊ HÀNG
                    </Text>
                    <Text as="h2" variant="headingLg">
                      {__helpers.formatNumber(sumarizeGroupIncome?._sum?.quantity || 0)}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </>}
          </div>

          <div className="timeline">
            <div className="outer">
              <For each={entities} fallback={<div style={{
              padding: '15px'
            }}>
                    <Text as="p">Chưa có một đơn nào.</Text>
                  </div>}>
                {group_income_loop}
              </For>
            </div>
          </div>

          <br />
          <br />
        </div>
      </Page>
    </>;
}