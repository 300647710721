import { Badge, Button, LegacyStack, Modal, RadioButton, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { AlertCircleIcon, ArrowDownIcon, EditIcon, XSmallIcon } from '@shopify/polaris-icons';
import helpers from '../../helpers';
import { useAuth } from 'context/AuthContext';
import { useDeleteMedia, useGetMedia, usePartialUpdateMedia } from 'queries/media.query';
export default function MediaView({
  show = false,
  current_media_id,
  onClose
}: {
  show: boolean;
  current_media_id: string;
  onClose: () => void;
}) {
  const {
    data: entity,
    isPending: loading,
    mutate: getMedia
  } = useGetMedia();
  useEffect(() => {
    if (current_media_id) getMedia(current_media_id);
  }, [current_media_id]);
  const {
    mutate: deleteEntity
  } = useDeleteMedia();
  const {
    mutate: partialUpdateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = usePartialUpdateMedia();
  useEffect(() => {
    if (updateSuccess) getMedia(current_media_id);
  }, [updateSuccess, current_media_id]);
  const {
    user
  } = useAuth();
  const {
    user_id,
    user_role
  } = user;
  const [showFormEdits, setShowFormEdits] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [formvalue, setFormvalue] = useState('');
  const [optionvalue, setOptionvalue] = useState('disabled');

  /** reset */
  useEffect(() => {
    if (!show) {
      setDeleteConfirm(false);
      setShowFormEdits(false);
    }
  }, [show]);
  const handChangeFormvalue = useCallback(newValue => setFormvalue(newValue), []);
  const handleOptionChange = useCallback((_checked, newValue) => setOptionvalue(newValue), []);
  const saveForm = () => {
    partialUpdateEntity({
      media_id: entity?.media_id,
      media_description: formvalue,
      private_access: optionvalue === 'disabled' ? 0 : 1
    });
    setShowFormEdits(false);
  };
  const cancelForm = () => {
    setShowFormEdits(false);
  };
  useEffect(() => {
    if (entity) {
      setFormvalue(entity?.media_description);
      setOptionvalue(entity?.private_access === 1 ? 'optional' : 'disabled');
    }
  }, [entity]);
  const deleteFile = useCallback(() => {
    deleteEntity(entity?.media_id);
    setTimeout(() => {
      onClose();
    }, 1000);
  }, [entity]);
  const deleteFileConfirm = useCallback(() => {
    setDeleteConfirm(deleteConfirm => !deleteConfirm);
  }, []);
  const showFormEdit = () => {
    setShowFormEdits(true);
  };
  const editForm = <>
      <Text as="h4">Quyền truy cập</Text>
      <br />
      <LegacyStack vertical>
        <RadioButton label="Riêng tư" helpText="Chỉ người thêm có thể xem hoặc tải xuống tệp." checked={optionvalue === 'optional'} id="optional" name="accounts" onChange={handleOptionChange} />
        <RadioButton label="Công khai" helpText="Mọi người đều có thể xem và tải xuống." id="disabled" name="accounts" checked={optionvalue === 'disabled'} onChange={handleOptionChange} />
      </LegacyStack>
      <br />
      <Text as="h4">Ghi chú</Text>
      <TextField label="" value={formvalue} onChange={handChangeFormvalue} maxLength={200} multiline={2} autoComplete="off" showCharacterCount />
      <Button variant="plain" onClick={saveForm} loading={updating}>
        Lưu
      </Button>
      &nbsp; hoặc &nbsp;
      <Button variant="plain" onClick={cancelForm} loading={updating}>
        Huỷ
      </Button>
    </>;
  return <Modal open={show} onClose={onClose} title="View File" titleHidden secondaryActions={[{
    content: 'Đóng',
    onAction: () => onClose()
  }]}>
      <Modal.Section>
        {!entity && !loading ? <Text as="h4">Oh! Data not found!</Text> : <>
            <Text as="h4">
              <strong>{entity?.media_filename}</strong>
            </Text>
            <br />
            <LegacyStack>
              <LegacyStack.Item>
                <Badge tone="success">{entity?.media_filetype}</Badge>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Badge tone="critical">{helpers.bytesToSize(entity?.media_filesize || 0)}</Badge>
              </LegacyStack.Item>
            </LegacyStack>
            <br />
            <p>Người thêm: {entity?.user?.display_name}</p>
            <p>
              Tạo lúc: <time>{entity?.createdAt ? dateandtime.format(new Date(Number(entity.createdAt)), 'HH:mm:ss DD-MM-YYYY') : '-'}</time>
            </p>
            <p>
              Cập nhật lúc: <time>{entity?.updatedAt ? dateandtime.format(new Date(Number(entity.updatedAt)), 'HH:mm:ss DD-MM-YYYY') : '-'}</time>
            </p>
            <br />
            {showFormEdits ? editForm : null}
            {!showFormEdits ? <>
                <div>
                  <Text as="h4">{entity?.private_access === 1 ? 'Riêng tư' : 'Công khai'}</Text>
                  <p>{entity?.private_access === 1 ? 'Chỉ người thêm có thể xem hoặc tải xuống tệp.' : 'Mọi người đều có thể xem và tải xuống.'}</p>
                </div>
                <br />
                <div>
                  <p>
                    Ghi chú:{' '}
                    <Text as="p" tone="caution">
                      {entity?.media_description || 'Không có!'}
                    </Text>
                  </p>
                </div>
              </> : null}
            <br />
            <br />
            <LegacyStack>
              <LegacyStack.Item>
                <Button download variant="plain" icon={ArrowDownIcon} onClick={() => window.open(process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + `/${entity?.media_url}`, '_blank')}>
                  {' '}
                  Tải xuống
                </Button>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Button variant="plain" disabled={user_id !== entity?.createdBy && user_role !== 'admin'} icon={EditIcon} onClick={showFormEdit}>
                  Sửa
                </Button>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Button disabled={user_id !== entity?.createdBy && user_role !== 'admin'} onClick={deleteFileConfirm} variant="plain" icon={XSmallIcon}>
                  {' '}
                  Xoá
                </Button>
              </LegacyStack.Item>
              {deleteConfirm ? <LegacyStack.Item>
                  <Button onClick={deleteFile} variant="plain" icon={AlertCircleIcon} loading={updating}>
                    {' '}
                    Bạn có chắc muốn xoá?
                  </Button>
                </LegacyStack.Item> : null}
            </LegacyStack>
          </>}
      </Modal.Section>
    </Modal>;
}