import { Frame, FormLayout, TextField, Form, Button, Link, Text, Banner, Loading, InlineStack, Box, Popover, ActionList, Thumbnail } from '@shopify/polaris';
import 'media/css/login_register.scss';
import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import LoginLogo from 'media/images/LoginLogo.svg';
import { useLocation } from 'react-router-dom';
import { lengthLessThan, lengthMoreThan, notEmptyString, useField, useForm } from '@shopify/react-form';
import helpers from '../helpers';
import __, { ___ } from 'languages/index';
import { ViewIcon, HideIcon, LanguageTranslateIcon, PersonLockFilledIcon, KeyIcon } from '@shopify/polaris-icons';
import { startAuthentication, browserSupportsWebAuthn, platformAuthenticatorIsAvailable } from '@simplewebauthn/browser';
import { Helmet } from 'react-helmet-async';
import { useGenerateWebAuthLoginOption, useUserLogin, useVerifyWebAuthlogin } from 'queries/user.query';
export default function Login() {
  const {
    mutateAsync: generateWebAuthLoginOption
  } = useGenerateWebAuthLoginOption();
  const {
    mutateAsync: login,
    isPending: loading
  } = useUserLogin();
  const {
    mutateAsync: verifyWebAuthlogin
  } = useVerifyWebAuthlogin();
  const __passport = (window as any).__passport || '';
  const [viewPasswordMode, setViewPasswordMode] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState('');
  const clearInternalError = useCallback(() => {
    setInternalErrorShow('');
  }, []);

  /** Bắt đầu redirect ở đây... */
  const redirectTo = useCallback(async () => {
    /** thi thoảng phải đăng nhập 2 lần mới thành công, có thể do bug chuyển hướng nhanh quá */
    await helpers.sleep(1000);
    let redirect_to = process.env.REACT_APP_PUBLIC_URL;
    window.location.href = decodeURIComponent(redirect_to);
  }, []);

  /**
   * Khai báo field cho form!
   */
  const useFields = {
    user_input: useField<string>({
      value: '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthLessThan(50, 'Bạn điền quá dài!'), lengthMoreThan(6, 'Email/số điện thoại quá ngắn!'), inputValue => {
        if (!helpers.isPhoneNumber(inputValue)) if (!helpers.isEmail(inputValue)) {
          return 'Định dạng Email không hợp lệ! Vui lòng kiểm tra lại email của bạn!';
        }
        if (helpers.isUTF8(inputValue)) {
          return 'Trường này không nên có mã Unicode, bạn vui lòng kiểm tra lại!';
        }
      }]
    }),
    password: useField<string>({
      value: '',
      validates: [notEmptyString('Trường này không được để trống.'), lengthMoreThan(6, 'Mật khẩu quá ngắn!'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'Không nên dùng mã Unicode trong mật khẩu của bạn!';
        }
      }]
    })
  };
  const {
    fields,
    submit,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(form) {
      await login({
        user_input: form.user_input,
        password: form.password,
        device_type: 'website',
        device_signature: 'a_ignore' /** For notification, but website doesn't need it ... */,
        device_uuid: __passport
      }).then(({
        data
      }: any) => {
        let {
          access_token,
          refresh_token,
          expires_at
        } = data;
        helpers.cookie_set('AT', access_token, 30);
        helpers.cookie_set('RT', refresh_token, 30);
        helpers.cookie_set('EA', expires_at, 30);
        redirectTo();
      }).catch(e => {
        setInternalErrorShow(e?.message);
      });
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  const [languagePopoverStatus, setLanguagePopoverStatus] = useState(false);
  const toggleLanguagePopoverActive = useCallback(() => setLanguagePopoverStatus(active => !active), []);
  const setDefaultLanguage = useCallback((lang_code: string) => {
    localStorage.setItem('language', lang_code);
    setLanguagePopoverStatus(false);
  }, []);
  const [waiting_for_webauthn, setWaiting_for_webauthn] = useState(false);
  const handleLoginByWebAuth = async () => {
    try {
      setWaiting_for_webauthn(true);
      const {
        data: options
      } = await generateWebAuthLoginOption();
      let {
        challenge
      } = options;
      const PublicKeyCredential = await startAuthentication({
        optionsJSON: options,
        useBrowserAutofill: false,
        verifyBrowserAutofillInput: false
      });
      const {
        data: verificationJSON
      } = await verifyWebAuthlogin({
        data: PublicKeyCredential,
        challenge: challenge,
        device_type: 'website',
        device_signature: 'a_ignore' /** For notification, but website doesn't need it ... */,
        device_uuid: __passport
      });
      if (verificationJSON.verified) {
        setInternalErrorShow('');
        let {
          access_token,
          refresh_token,
          expires_at
        } = verificationJSON.sessionData;
        helpers.cookie_set('AT', access_token, 30);
        helpers.cookie_set('RT', refresh_token, 30);
        helpers.cookie_set('EA', expires_at, 30);

        /** Đánh dấu lại là tên này có dùng biometric Authentication và có thể dùng cho các giao dịch sau ... */
        helpers.cookie_set('BA', '1', 365);
        redirectTo();
      } else {
        setInternalErrorShow(__('login_via_webauthn_fail'));
        helpers.cookie_set('BA', '0', 365);
        setWaiting_for_webauthn(false);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setInternalErrorShow(__('login_fail_this_device_has_not_registered_yet'));
      setWaiting_for_webauthn(false);
    }
  };
  return <Frame>
      <Helmet>
        <title>{__('login_page_title')}</title>
      </Helmet>
      {loading ? <Loading /> : null}
      <div id="login_register_outer_wrap">
        <InlineStack blockAlign="center" align="center">
          <div id="login_page" style={{
          maxWidth: '400px'
        }}>
            <InlineStack blockAlign="center" align="end" gap="200">
              <Popover active={languagePopoverStatus} activator={<Button onClick={toggleLanguagePopoverActive} disclosure variant="plain" icon={LanguageTranslateIcon}>
                    {__('switch_language_button')}
                  </Button>} autofocusTarget="first-node" onClose={toggleLanguagePopoverActive}>
                <ActionList actionRole="menuitem" items={[{
                content: 'Vietnamese',
                onAction: () => setDefaultLanguage('vi'),
                icon: LanguageTranslateIcon
              }, {
                content: 'English',
                onAction: () => setDefaultLanguage('en'),
                icon: LanguageTranslateIcon
              }]} />
              </Popover>
            </InlineStack>

            <br />
            {internalErrorShow && <>
                <Banner tone="critical" onDismiss={clearInternalError}>
                  {internalErrorShow}
                </Banner>
                <br />
              </>}

            <Box background="bg-fill" padding={'400'} borderRadius="400">
              <Form onSubmit={submit}>
                <InlineStack wrap={false} gap="200" blockAlign="center" align="start">
                  <div>
                    <Thumbnail size="medium" source={LoginLogo} alt={'Login Logo'} />
                  </div>
                  <div>
                    <Text as="h3" variant="headingLg" fontWeight="regular">
                      {__('welcome')}
                    </Text>
                    <Text as="h6" variant="bodySm" fontWeight="regular">
                      {__('login')}
                    </Text>
                  </div>
                </InlineStack>

                <br />

                <FormLayout>
                  <TextField type="text" disabled={loading} placeholder="" label={__('login_by_your_account')} {...fields.user_input} requiredIndicator autoComplete="off" />
                  <TextField disabled={loading} label={__('login_by_your_password')} placeholder="******" type={viewPasswordMode ? 'text' : 'password'} requiredIndicator autoComplete="off" suffix={<InlineStack blockAlign="center">
                        <Button variant="plain" onClick={() => setViewPasswordMode(!viewPasswordMode)} icon={viewPasswordMode ? ViewIcon : HideIcon}></Button>
                      </InlineStack>} {...fields.password} />

                  <Button icon={PersonLockFilledIcon} submit variant="primary" fullWidth disabled={loading} onClick={submit} key={'button_login_normal'} size="large">
                    {__('login_button_context')}
                  </Button>

                  {browserSupportsWebAuthn() && platformAuthenticatorIsAvailable() && <Button size="large" icon={KeyIcon} fullWidth loading={waiting_for_webauthn} key="BA" onClick={handleLoginByWebAuth}>
                      {__('login_using_biometric_authentication_button')}
                    </Button>}

                  <Text as="p" key="C">
                    {___('Forgot your password? {recover_link} to recover!', {
                    recover_link: <Link url="/recover_password">{__('click_here')}</Link>
                  })}
                  </Text>
                </FormLayout>
              </Form>
            </Box>
          </div>
        </InlineStack>
      </div>
    </Frame>;
}