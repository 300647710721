import { Autocomplete, Avatar, Icon, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import __ from 'languages/index';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { TypedPosts, useGetPosts } from 'queries/posts.query';
import __helpers from 'helpers/index';
type TypedSearchPost = {
  current_post_id?: bigint | string;
  onClose?: (val: TypedPosts) => void;
  label: string;
  placeholder?: string;
  disabled?: boolean;
};
export default function QuickSearchPost({
  disabled,
  current_post_id = null,
  onClose,
  label,
  placeholder = 'Tìm một bài đăng/trang'
}: TypedSearchPost) {
  const [mainQuery, setMainQuery] = useState({
    query: '',
    sort: 'createdAt:desc',
    limit: 5
  });
  const {
    refetch: searchPost,
    isPending: postLoading,
    data
  } = useGetPosts(mainQuery);
  const [entities, setEntities] = useState<TypedPosts[]>([]);
  const [postSelected, setPostSelected] = useState<string[]>([]);
  const [postQuickSearchText, setPostQuickSearchText] = useState<string>('');
  const [postListForQuickOption, setPostListForQuickOption] = useState([]);
  useEffect(() => {
    if (data) {
      const {
        body
      } = data;
      setEntities(body);
    }
  }, [data]);
  const onQueryToServer = useCallback(async () => {
    return await searchPost();
  }, []);
  useEffect(() => {
    let innerPost = [];
    if (entities) for (let post of entities) {
      innerPost.push({
        value: post.post_id,
        label: post.post_title,
        media: <Thumbnail source={__helpers.getMediaLink(post.post_thumbnail_to_media ? post.post_thumbnail_to_media.media_thumbnail ? post.post_thumbnail_to_media.media_thumbnail['scale-128'] : '' : '')} alt="" />
      });
      if (current_post_id && String(post.post_id) === String(current_post_id)) {
        setPostQuickSearchText(post.post_title);
      }
    }
    setPostListForQuickOption(innerPost);
  }, [entities, current_post_id]);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce((_value: string) => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updatePostText = useCallback((value: string) => {
    setMainQuery(prevQuery => ({
      ...prevQuery,
      query: value
    }));
    setPostQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const findPostSelectedInEntities = useCallback(post_id => {
    if (Array.isArray(entities)) {
      let a = entities.filter(a => a.post_id === post_id);
      return a[0] || null;
    }
  }, [entities]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = postListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setPostSelected(selected);
    setPostQuickSearchText(selectedValue[0] || '');
    onClose?.call(this, findPostSelectedInEntities(selected[0]));
  }, [postListForQuickOption]);
  useEffect(() => {
    onChangeMainQueryCallback('');
  }, []);
  const textFieldSelectPost = <>
      <Autocomplete.TextField align="left" onChange={updatePostText} label={label} disabled={disabled} value={postQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder={placeholder} autoComplete="off" />
    </>;
  return <>
      <Autocomplete options={postListForQuickOption} selected={postSelected} onSelect={updateSelection} textField={textFieldSelectPost} loading={postLoading} id="quickSearchPostModalPopover" />
    </>;
}