import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedThumbnail } from "./media.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedBanner {
    banner_id?: string;
    media_id?: string;
    banner_title?: string;
    banner_description?: string;
    banner_meta?: TypeBannerMeta;
    banner_size?: string;
    banner_status?: number;
    createdAt?: string;
    createdBy?: string;

    media?: {
        media_id: string,
        media_thumbnail?: TypedThumbnail
        media_url: string,
    }
    banner_group?: string
}

export interface TypeBannerMeta {
    link?: string;
    external_link?: string;
    product?: string;
    category?: string;
}

type typedUpdateManyEntry = {
    banner_id: string;
    banner_status: string;
};


type IQuery = TypedBanner & IQueryParams;


/*
* List of all app
*/
export function useGetBanners(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["banner/fetch_entity_list"],
        queryFn: () => axios.get<TypedBanner[]>(`/banner${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetBanner(banner_id: string) {
    return useQuery({
        queryKey: ['banner/fetch_entity'],
        queryFn: () => axios.get<TypedBanner>(`/banner/${banner_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetBanner() {
    return useMutation({
        mutationKey: ['banner/fetch_entity'],
        mutationFn: (banner_id: string) => axios.get<TypedBanner>(`/banner/${banner_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateBanner() {
    return useMutation({
        mutationKey: ['banner/update_entity'],
        mutationFn: ({ banner_id, ...rest }: TypedBanner) => axios.patch<any>(`/banner/${banner_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['banner/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['banner/fetch_entity'] });
        }
    });
}


export function useBulkUpdateBanners() {
    return useMutation({
        mutationKey: ['banner/update_entities'],
        mutationFn: (entities: typedUpdateManyEntry) => axios.patch<any>(`/banner/bulkUpdateEntities`, helpers.cleanEntity(entities)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['banner/fetch_entity_list'] });
        }
    });
}


export function useCreateBanner() {
    return useMutation({
        mutationKey: ['banner/create_entity'],
        mutationFn: (entity: TypedBanner) => axios.post<any>(`/banner`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['banner/fetch_entity_list'] });
        }
    });
}


export function useDeleteBanner() {
    return useMutation({
        mutationKey: ['banner/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/banner/${app_id}`)
    });
}


/********************************************************** *
 * BANNER TAG
********************************************************** */

export interface TypedBanner_tag {
    id?: string;
    tag_name?: string;
    tag_slug?: string;
    createdAt?: string;
}

type IIQuery = TypedBanner_tag & IQueryParams;


/*
* List of all app
*/
export function useGetBannerTags(object: IIQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["banner_tag/fetch_entity_list"],
        queryFn: () => axios.get<TypedBanner_tag[]>(`/banner_tag${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useAddBannerTag() {
    return useMutation({
        mutationKey: ['banner_tag/create_entity'],
        mutationFn: (entity: TypedBanner_tag) => axios.post<any>(`/banner_tag/`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['banner_tag/fetch_entity_list'] });
        }
    });
}


export function useDeleteBannerTag() {
    return useMutation({
        mutationKey: ['banner_tag/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/banner_tag/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['banner_tag/fetch_entity_list'] });
        }
    });
}