import { useEffect } from 'react';
import { BlockStack, Divider, EmptyState, InlineGrid, InlineStack, Link, SkeletonDisplayText, Text, Thumbnail } from '@shopify/polaris';
import { useCustomerRecentReferrer, useCustomerReferrerSumarize } from 'queries/customer.query';
import __helpers from 'helpers/index';
import user_referrer_placeholder from 'media/lottie_files/user_referrer_placeholder.json';
import Lottie from 'lottie-react';
import Capacity from 'components/Capacity';
export default function CustomerReferrer({
  user_id
}: {
  user_id: string;
}) {
  const {
    data,
    mutateAsync: getCustomerReferrerSumarize,
    isPending
  } = useCustomerReferrerSumarize();
  const {
    data: recentReferrerData,
    mutate: getCustomerRecentReferrer
  } = useCustomerRecentReferrer();
  useEffect(() => {
    try {
      getCustomerReferrerSumarize(user_id);
      getCustomerRecentReferrer(user_id);
    } catch (e) {}
  }, [user_id]);
  return data && <Capacity current_user_can={'view_affiliates'} onFail={<EmptyState image={''} heading="Không có quyền truy cập" />}>
        <BlockStack gap={'400'}>
          <Text as="h4" variant="headingMd" tone="base">
            Tổng quan
          </Text>
          <Text as="p" tone="subdued" key="jhdfudhfkuj">
            Đây là tóm tắt những người mà khách hàng giới thiệu được, xem chi tiết hơn{' '}
            <Link url={`/affiliate/view/${user_id}`}>vui lòng truy cập tại đây</Link>.
          </Text>

          <InlineGrid gap={'200'} columns={{
        xs: 1,
        md: 4
      }}>
            {[0, 1, 2, 3, 4].map(e => {
          let dx: any = data[`level` + e] || undefined;
          if (!dx) return null;
          return <BlockStack gap="200">
                  <Text as="p">{`Cấp ${e + 1}`}</Text>
                  <Divider />
                  <BlockStack gap={'100'}>
                    <Text as="p" variant="headingLg" tone="subdued">
                      {isPending ? <SkeletonDisplayText /> : `${__helpers.formatNumber(dx._count)} người`}
                    </Text>
                    <Text as="p" variant="bodySm" tone="subdued">
                      {isPending ? <SkeletonDisplayText /> : `Tổng ${__helpers.formatNumber(dx.total_order_count)} đơn`}
                    </Text>
                    <Text as="p" variant="bodySm" tone="subdued">
                      {isPending ? <SkeletonDisplayText /> : `Tổng doanh thu ${__helpers.formatNumber(dx.total_moneyspent_count)} đ`}
                    </Text>
                  </BlockStack>
                </BlockStack>;
        })}
          </InlineGrid>

          <br />
          <Divider />
          <br />
          <Text as="h4" variant="headingMd" tone="base">
            Vừa mới giới thiệu
          </Text>
          {!__helpers.isEmpty(recentReferrerData) ? <>
              <InlineGrid columns={{
          md: 4,
          xs: 1
        }} gap={'100'}>
                {recentReferrerData?.map((el, index) => {
            return <InlineStack align="start" blockAlign="center" gap={'200'} key={index + '_latest_referrer_A'}>
                      <Thumbnail size="small" source={el.user_avatar ? __helpers.getMediaLink(el.user_avatar) : 'https://placehold.co/100x100'} alt={''} />
                      <BlockStack>
                        <Text as="h4" fontWeight="bold" tone="base">
                          {el.display_name}
                        </Text>
                        <Text as="p" tone="subdued" variant="bodyXs">
                          Cấp {el.level} - {__helpers.subtractTimeHistory(el.createdAt)}
                        </Text>
                      </BlockStack>
                    </InlineStack>;
          })}
              </InlineGrid>
            </> : <EmptyState image={''} heading="">
              <p>
                <Lottie className="user_referrer_placeholder" animationData={user_referrer_placeholder} loop />
              </p>
              <BlockStack>
                <Text as="p" variant="headingMd" tone="subdued">
                  Khách hàng này chưa mời ai
                </Text>
              </BlockStack>
            </EmptyState>}
        </BlockStack>
      </Capacity>;
}