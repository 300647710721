import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BlockStack, Button, DataTable, Text, InlineStack, Thumbnail } from '@shopify/polaris';
import { EditIcon, DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import __ from 'languages/index';
import { TypedLuckyPresent, useDeletePresent, useGetGameLuckyListPresent } from 'queries/game.query';
import __helpers from 'helpers/index';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import PresentModal from './present.modal';
export function PresentList() {
  const {
    data: presents
  } = useGetGameLuckyListPresent({
    limit: 20,
    sort: 'createdAt:desc'
  });
  const {
    mutateAsync: deletePresent
  } = useDeletePresent();
  const [presentListing, setPresentListing] = useState<TypedLuckyPresent[]>([]);
  useEffect(() => {
    if (!presents) return;
    setPresentListing(presents);
  }, [presents]);

  /** Present */

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async present_id => {
    const confirmed = await showDeleteConfirm('Xóa quà tặng này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deletePresent(present_id);
    }
  }, []);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const current_id = useRef(null);
  return <>
      <PresentModal current_id={current_id.current} show={showCreateModal} onClose={() => setShowCreateModal(false)} />
      <Button icon={PlusIcon} variant="tertiary" onClick={() => {
      current_id.current = null;
      setShowCreateModal(true);
    }}>
        Thêm quà tặng
      </Button>
      <br />
      <br />

      <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'numeric']} headings={['Tên quà', 'Loại quà', 'Khuyến mãi?', 'Xu?', 'Mã đổi thưởng?', 'Tỉ lệ trúng(%)', '-']} rows={Array.isArray(presentListing) && presentListing.length > 0 ? presentListing.map((present, index) => {
      return [<InlineStack gap={'200'} align="start" blockAlign="center">
                    <Thumbnail source={__helpers.getMediaLink(present.media ? present.media.media_thumbnail ? present.media.media_thumbnail['128x128'] : '' : '')} alt="" />
                    <BlockStack>
                      <Text as="p" fontWeight="bold" variant="bodyMd">
                        {present.present_name}
                      </Text>
                      <Text as="p" variant="bodySm" tone="subdued">
                        {present.present_name_short}
                      </Text>
                    </BlockStack>
                  </InlineStack>, present.present_type, present.voucher ? present.voucher.voucher_code : '--', present.wallet_type ? `${__helpers.formatNumberWithCommas(present.wallet_number)} ${present.wallet_type.wallet_sign}` : '--', present?.wallet_code ? 'Có' : '--', present.reward_probability,
      // present?.present_slug,
      <InlineStack gap="400" align="end">
                    <Button variant="plain" icon={EditIcon} onClick={() => {
          current_id.current = present.present_id;
          setShowCreateModal(true);
        }} />

                    <Button variant="plain" icon={DeleteIcon} onClick={() => {
          handleDelete(present.present_id);
        }} />
                  </InlineStack>];
    }) : []} />
    </>;
}