import { Card, Text, Link, Page, DataTable, Badge, Avatar, InlineStack, BlockStack } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from '../../helpers';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
import UserNewModel from '../customer/customer.new';
import Hotkeys from 'react-hot-keys';
import CustomerUploadModal from '../customer/customer.upload.modal';
import FilterAdvance from './filter';
import { CircleChevronUpIcon, PlusIcon } from '@shopify/polaris-icons';
import dateandtime from 'date-and-time';
import { TypedCustomer } from 'interface/customer.model';
import { Helmet } from 'react-helmet-async';
import __helpers from '../../helpers';
import { useGetCustomers } from 'queries/customer.query';
import { useAuth } from 'context/AuthContext';
export default function CustomerList() {
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetCustomers(mainQuery);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);

  /**
   * Upload Modal
   */

  const [uploadModal, setUploadModal] = useState(false);
  const toggleShowUploadModal = useCallback(() => setUploadModal(true), []);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  // lấy quyền
  const {
    currentUserCan
  } = useAuth();
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product', '/customer' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const renderItem = (customerData: TypedCustomer) => {
    if (!customerData) return;
    const {
      user_id,
      display_name,
      bio,
      user_role,
      customer_to_user,
      user_phonenumber,
      user_email,
      user_avatar,
      createdAt,
      user_verified_phone,
      user_verified_email,
      user_to_rank,
      referrer
    } = customerData;
    const customer_display_name = display_name || user_email || user_phonenumber || 'noname';
    const referrer_data = referrer ? referrer.referrer_data : null;
    return [<Link key={user_id} removeUnderline url={'/customer/view/' + user_id}>
        <div className={customer_to_user?.is_vip ? 'has_vip' : ''}>
          <InlineStack gap="200" align="start" blockAlign="center">
            <Avatar source={user_avatar ? __helpers.getMediaLink(user_avatar) : null} name={customer_display_name} initials={customer_display_name.charAt(0)} size="sm" />
            <BlockStack>
              <Text fontWeight="bold" as="p">
                {helpers.trimMiddleString(customer_display_name)}{' '}
              </Text>
              {user_to_rank && currentUserCan(['view_affiliates']) && <Text as="p" tone="subdued" variant="bodySm">
                  Rank: {user_to_rank?.rank?.rank_name}
                </Text>}
            </BlockStack>
          </InlineStack>
        </div>
      </Link>, ...(currentUserCan(['view_affiliates']) ? [<Badge tone={user_role === 'customer' ? 'info' : 'attention'}>{__(`user_role_` + user_role)}</Badge>] : []), user_phonenumber ? user_phonenumber : '-', helpers.formatNumber(customer_to_user?.customer_order_count || 0, ','), helpers.formatNumber(customer_to_user?.customer_moneyspent_count || 0, ','), ...(currentUserCan(['view_affiliates']) ? [referrer_data ? <Link removeUnderline url={`/customer/view/${referrer_data.user_id}`}>
          {referrer_data.display_name + ` (${referrer_data?.user_phonenumber || referrer_data?.user_email})`}
        </Link> : <>
          <Badge></Badge>
          <Badge></Badge>
        </>] : []), createdAt ? dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY') : '-'];
  };
  return <>
      <Helmet>
        <title>Danh sách khách hàng</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Khách hàng" compactTitle primaryAction={{
      content: 'Thêm khách hàng',
      icon: PlusIcon,
      onAction: toggleNewActive,
      disabled: !currentUserCan(['manage_customers', 'edit_customers'])
    }} secondaryActions={[{
      content: 'Tải lên',
      icon: CircleChevronUpIcon,
      onAction: toggleShowUploadModal,
      disabled: !currentUserCan(['manage_customers', 'edit_customers'])
    }]}>
          <Card padding="0">
            <FilterAdvance loading={loading} onCallback={filterCallback} />
            <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={currentUserCan(['view_affiliates']) ? ['Tên khách hàng', 'Loại tài khoản', 'Điện thoại', 'Số đơn', 'Đã chi tiêu', 'Giới thiệu bởi', 'Tạo lúc'] : ['Tên khách hàng', 'Điện thoại', 'Số đơn', 'Đã chi tiêu', 'Tạo lúc']} rows={entities?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}

      <UserNewModel onClose={() => {
      setNewModelactive(false);
    }} show={newModelactive} />

      {uploadModal ? <CustomerUploadModal onClose={() => setUploadModal(false)} /> : null}

      <Hotkeys keyName="shift+enter" onKeyDown={(keyname: any, event: any, _handler) => {
      event.preventDefault();
      toggleNewActive();
    }} />
    </>;
}