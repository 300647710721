import { compiledBlock as _compiledBlock } from "million/react";
const KeyMapIndex_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}
      {_props.v2}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "KeyMapIndex_1",
  portals: ["v0", "v1", "v2"]
});
const KeyMapIndex = function KeyMapIndex() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search: searchParam
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(searchParam) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 35,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetAdminSettings(mainQuery);
  const {
    mutateAsync: deleteEntity,
    isPending: updating
  } = useDeleteSetting();
  const [entities, setEntities] = useState<TypedSettings[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (data) {
      const {
        entities: newEntities,
        totalItems: newTotalItems
      } = data;
      setEntities(newEntities);
      setTotalItems(newTotalItems);
    }
  }, [data]);

  /**
   * Lòng và lòng vòng, cuối cùng là để nó không gọi quá nhiều lần ...
   */
  const reduceLoad = useCallback((_mainQuery: any) => {
    let buildURLSearch = helpers.buildEndUrl(_mainQuery);
    if (searchParam !== buildURLSearch) window.history.replaceState(null, 'Keymap', '/keymap' + buildURLSearch);
    getEntities();
  }, []);
  const reducerLoading = useMemo(() => helpers.debounce(_value => reduceLoad?.call(this, _value), 900), []);
  useEffect(() => {
    reducerLoading(mainQuery);
  }, [mainQuery]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      // m = Object.assign({}, m, _value);
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const resourceIDResolver = entity => {
    return entity.setting_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(entities ?? [], {
    resourceIDResolver
  });
  const promotedBulkActions = [{
    content: 'Xóa bỏ',
    onAction: () => handleDelete(),
    icon: DeleteIcon
  }];
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa cài đặt này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      for (let setting_id of selectedResources) {
        await deleteEntity(setting_id);
      }
      clearSelection();
    }
  }, [selectedResources]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((settingData: TypedSettings) => {
    modalEntity.current = settingData;
    setShowModal(true);
  }, []);
  const rowMarkup = entities?.map((entity, index) => {
    let {
      setting_id,
      setting_name,
      setting_value
    } = entity;
    return <IndexTable.Row id={setting_id} key={setting_id} selected={selectedResources.includes(setting_id)} position={index}>
        <IndexTable.Cell>
          <Link removeUnderline onClick={() => clickToShowEditModal(entity)} key={index}>
            {setting_name}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{setting_value}</IndexTable.Cell>
      </IndexTable.Row>;
  });
  return /*@million jsx-skip*/<KeyMapIndex_1 v0={<Helmet>
        <title>Keymap</title>
      </Helmet>} v1={<Page title="Keymap" subtitle="Chỉ dành cho kỹ thuật viên, làm ơn ĐỪNG SỬA BẤT KỲ CÁI GÌ nếu bạn không biết nó để làm gì" primaryAction={{
    content: 'Thêm mới cài đặt',
    icon: PlusIcon,
    onAction: () => setShowModal(true)
  }}>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[{
        label: 'Ngày tạo',
        field: 'createdAt'
      }]} />
          <IndexTable resourceName={{
        singular: 'Cài đặt',
        plural: 'Cài đặt'
      }} itemCount={entities?.length || 0} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={[]} onSort={null} loading={updating} promotedBulkActions={promotedBulkActions} sortable={[]} headings={[{
        title: 'Name'
      }, {
        title: 'Value'
      }]}>
            {rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
      </Page>} v2={<NewSettingModal show={showModal} onClose={() => {
    modalEntity.current = null;
    setShowModal(false);
  }} entity={modalEntity.current} />} />;
};
import { block as _block } from "million/react";
import { Card, IndexTable, Link, Page, Text, useIndexResourceState } from '@shopify/polaris';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import helpers from 'helpers/index';
import { TypedSettings } from 'interface/settings.model';
import { useDeleteSetting, useGetAdminSettings } from 'queries/setting.query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import NewSettingModal from './new';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default KeyMapIndex;