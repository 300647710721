import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonLoading from 'components/skeletonPageLoading';
import { BlockStack, Button, ButtonGroup, Card, Checkbox, ContextualSaveBar, DescriptionList, Divider, Link, Page, Text, TextField } from '@shopify/polaris';
import { useNotification } from 'context/NotificationContext';
import { useUpdateSetting } from 'queries/setting.query';
import { useContinueGenerateThumbnailMedia, useRegenerateThumbnailMedia } from 'queries/media.query';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useField, useForm } from '@shopify/react-form';
import { useSettings } from 'context/SettingsContext';
export default function MediaSetting() {
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities,
    loading
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const {
    mutate: regenerateThumbnail
  } = useRegenerateThumbnailMedia();
  const {
    mutate: continueGenerateThumbnail
  } = useContinueGenerateThumbnailMedia();

  /** Nút tạo lại toàn bộ */
  const [loadingRegenerateThumbnail, setLoadingRegenerateThumbnail] = useState(false);
  const reGenerateThumbnailCallback = useCallback(async () => {
    setLoadingRegenerateThumbnail(true);
    regenerateThumbnail();
    await helpers.sleep(3000);
    setLoadingRegenerateThumbnail(false);
    addNotification('info', 'Server đang xóa và tạo lại ảnh đại diện.');
  }, []);

  /** Nút tiếp tục */
  const [loadingContinueGenerateThumbnail, setLoadingContinueGenerateThumbnail] = useState(false);
  const continueGenerateThumbnailCallback = useCallback(async () => {
    setLoadingContinueGenerateThumbnail(true);
    continueGenerateThumbnail();
    await helpers.sleep(1000);
    setLoadingContinueGenerateThumbnail(false);
    addNotification('info', 'Server đang tiếp tục xử lý ảnh đại diện.');
  }, []);

  /** Hết code lấy user role ra ... */
  const useFields = {
    file_size_upload: useField<string>({
      value: entities?.file_size_upload ?? '',
      validates: []
    }),
    sync_file_to_r2_option: useField<string>({
      value: String(entities?.sync_file_to_r2_option ?? '0'),
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          file_size_upload: String(values.file_size_upload || 0).trim(),
          sync_file_to_r2_option: Number(values.sync_file_to_r2_option)
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return <>
      <Helmet>
        <title>Cài đặt media</title>
      </Helmet>
      {loading ? <SkeletonLoading /> : <Page title="Cài đặt media" narrowWidth>
          <Card>
            <BlockStack gap="400">
              <Text as="h3">Upload size</Text>
              <TextField label="Maximum File size in upload form. (in MB)" autoComplete="off" type="number" disabled={loading} {...fields.file_size_upload} />

              <Text as="p" tone="subdued">
                Default is 20Mb, if you want to remove this limit, change it to 0
              </Text>

              <Divider />

              <Text as="h3">Tạo lại toàn bộ thumbnail trong thư viện media</Text>
              <ButtonGroup>
                <Button tone="critical" loading={loadingRegenerateThumbnail} onClick={reGenerateThumbnailCallback}>
                  ReGenerate Thumbnail
                </Button>
                <Button tone="critical" loading={loadingContinueGenerateThumbnail} onClick={continueGenerateThumbnailCallback}>
                  Continue Generate Thumbnail
                </Button>
              </ButtonGroup>

              <Text tone="critical" as="p">
                * Chú ý: hành động này tốn tài nguyên CPU của máy chủ, cân nhắc sử dụng khi cần thiết.
              </Text>
            </BlockStack>
          </Card>
          <br />
          <Card>
            <BlockStack gap="400">
              <Checkbox label="Đồng bộ media lên CloudFlare R2" checked={fields.sync_file_to_r2_option.value === '1'} onChange={newChecked => fields.sync_file_to_r2_option.onChange(newChecked ? '1' : '0')} />
              <Text tone="subdued" as={'p'}>
                Đồng bộ media lên CloudFlare R2 giúp tăng tốc độ tải media, giảm tải cho máy chủ. Nghĩa là các file của bạn vẫn giữa nguyên trên
                server này, nhưng chúng được tải lên CloudFlare R2 để phục vụ cho người dùng.
              </Text>

              {fields.sync_file_to_r2_option.value === '1' && <>
                  <Text as="h3">
                    Bạn cần có các thông tin sau đây để cấu hình, sau đó hỏi quản trị viên hệ thống để họ có thể cài đặt phần tiếp theo.
                  </Text>

                  <DescriptionList items={[{
              term: 'CLOUDFLARE_R2_ENDPOINT_HOST',
              description: '<your-account-id>.r2.cloudflarestorage.com'
            }, {
              term: 'CLOUDFLARE_R2_ACCESS_KEY_ID',
              description: '<your-access-key-id>'
            }, {
              term: 'CLOUDFLARE_R2_SECRET_ACCESS_KEY',
              description: '<your-secret-access-key>'
            }, {
              term: 'CLOUDFLARE_R2_BUCKET_NAME',
              description: 'bucket-name'
            }]} />

                  <Text as="p">
                    Khi bạn cần access các file trên CloudFlare R2, bạn cần trỏ một domain về DNS của Cloudflare, sau đó cài đặt theo{' '}
                    <Link url="https://developers.cloudflare.com/r2/buckets/public-buckets/#access-control" target="_blank">
                      hướng dẫn tại đây
                    </Link>
                    .
                  </Text>
                </>}
            </BlockStack>
          </Card>

          <br />
          <br />
          <br />
        </Page>}

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}