import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { BlockStack, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
import 'media/css/RichTextEditor.scss';
export default function TextFieldRichText({
  label,
  initialData,
  onCallback,
  requiredIndicator = false,
  error
}: {
  label: string;
  initialData: string;
  onCallback: (content: string) => void;
  requiredIndicator?: boolean;
  error?: string;
}) {
  const callbackToMainEditor = useMemo(() => {
    return helpers.debounce((value: string) => {
      onCallback?.call(this, value);
    }, 1500);
  }, [onCallback]);
  const handleEditorChange = (content: string) => {
    callbackToMainEditor(content);
  };
  const example_image_upload_handler = (blobInfo, progress): Promise<string> => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.REACT_APP_AJAX_UPLOAD_URL, true);
    xhr.setRequestHeader('X-Authorization', helpers.cookie_get('AT'));
    xhr.upload.onprogress = e => {
      progress(e.loaded / e.total * 100);
    };
    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({
          message: 'HTTP Error: ' + xhr.status,
          remove: true
        });
        return;
      }
      if (xhr.status < 200 || xhr.status >= 300) {
        reject('HTTP Error: ' + xhr.status);
        return;
      }
      const json = JSON.parse(xhr.responseText);
      if (!json || typeof json.media_url != 'string') {
        reject('Invalid JSON: ' + xhr.responseText);
        return;
      }
      resolve(helpers.getMediaLink(json.media_url));
    };
    xhr.onerror = () => {
      reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
    const formData = new FormData();
    formData.append('files', blobInfo.blob(), blobInfo.filename());
    xhr.send(formData);
  });
  return <BlockStack gap={'400'}>
      <br />
      <div className={error && `TinyMCE_editor_error_warning`}>
        {label && <p className={requiredIndicator && 'Polaris-Label__RequiredIndicator'}>{label}</p>}
        <Editor apiKey="gkxbp5cjv6mf6mvp3s1clinhtzfjf2oufy8p7g3y1wekyh3i" // Replace with your TinyMCE API key
      initialValue={initialData ?? ''} init={{
        min_height: 300,
        branding: false,
        resize: true,
        menubar: false,
        plugins: [
        // Core editing features
        'anchor', 'autolink',
        // 'charmap',
        // 'codesample',
        'emoticons', 'image', 'link', 'lists', 'media',
        // 'searchreplace',
        'table', 'visualblocks', 'wordcount'
        // Your account includes a free trial of TinyMCE premium features
        // Try the most popular premium features until Dec 30, 2024:
        // 'checklist',
        // 'mediaembed',
        // 'casechange',
        // 'export',
        // 'formatpainter',
        // 'pageembed',
        // 'a11ychecker',
        // 'tinymcespellchecker',
        // 'permanentpen',
        // 'powerpaste',
        // 'advtable',
        // 'advcode',
        // 'editimage',
        // 'advtemplate',
        // 'tableofcontents',
        // 'footnotes',
        // 'mergetags',
        // 'autocorrect',
        // 'typography',
        // 'inlinecss',
        // 'markdown',
        // 'importword',
        // 'exportword',
        // 'exportpdf',
        ],
        toolbar_mode: 'wrap',
        toolbar: 'bold italic underline strikethrough | link image media table | align | numlist bullist | removeformat',
        placeholder: 'Nhập nội dung tại đây!',
        language: 'vi',
        images_upload_handler: example_image_upload_handler,
        content_style: 'img {max-width: 100%; height: auto}'
      }} onEditorChange={handleEditorChange} />
        {error && <Text as="p" variant="bodySm" tone="critical">
            {error}
          </Text>}
      </div>
    </BlockStack>;
}