import React, { useState, useCallback, useEffect } from 'react';
import { EmptyState, DataTable, Badge, Tooltip, Link, Card } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import SkeletonLoading from 'components/skeletonPageLoading';
import { TypedSession, useDeleteSession, useGetSessions } from 'queries/session.query';
export default function CustomerLoginHistory({
  user_id
}) {
  // const entities = useAppSelector((state) => state.session.entities);
  // const sessionLoading = useAppSelector((state) => state.session.loading);

  const {
    mutateAsync: deleteEntity
  } = useDeleteSession();
  const {
    data,
    refetch: getSessionList,
    isLoading
  } = useGetSessions({
    user_id: user_id,
    limit: 40,
    page: 1
  });
  const [sessions, setSessions] = useState(null);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setSessions(body);
    }
  }, [data]);
  useEffect(() => {
    getSessionList();
  }, []);
  const logOutMe = useCallback(async (session_id: bigint | string | number) => {
    try {
      await deleteEntity(session_id);
    } catch (e) {}
    let n = sessions.filter(el => {
      return el.session_id !== session_id;
    });
    setSessions(n);
  }, [sessions]);
  const emptyData = <EmptyState heading="Người này chưa đăng nhập bao giờ!" image={null}>
      <p>Thử lại sau!</p>
    </EmptyState>;
  const renderItem = (session: TypedSession) => {
    const {
      session_id,
      session_active,
      session_ip,
      createdAt,
      updatedAt,
      device
    } = session;
    return [device?.system_type, session_ip, <div style={{
      maxWidth: '300px',
      whiteSpace: 'normal'
    }}>{device?.user_agent}</div>, session_active ? <Tooltip content={'Click để đăng xuất khỏi phiên này.'}>
          <Link onClick={() => logOutMe(session_id)}>
            <Badge tone="success">active</Badge>
          </Link>
        </Tooltip> : <Badge tone="critical">deactive</Badge>, dateandtime.format(new Date(Number(updatedAt ?? createdAt)), 'HH:mm:ss DD-MM-YYYY')];
  };
  return <>
      {isLoading ? <SkeletonLoading /> : <Card padding="0">
          {sessions && sessions.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['Device', 'IP', 'UserAgent', 'Active', 'last Active']} rows={sessions?.map(renderItem)} /> : emptyData}
        </Card>}
    </>;
}