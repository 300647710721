import { Avatar, Card, IndexTable, InlineGrid, InlineStack, Link, Page, Text, Thumbnail } from '@shopify/polaris';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import helpers from 'helpers/index';
import { TypedProductWishlist, useGetProductWishlist } from 'queries/wishlist.query';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dateandtime from 'date-and-time';
import { Helmet } from 'react-helmet-async';
export default function Wishlist() {
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data: entities,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetProductWishlist(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedProductWishlist[]>([]);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setRecords(body);
      setTotalItems(totalItems);
    }
  }, [entities]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Wishlist', '/wishlist' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const rowMarkup = records?.map(({
    wishlist_id,
    createdAt,
    user,
    product,
    product_variant,
    user_id,
    product_id,
    variant_id
  }, index) => <IndexTable.Row id={`` + wishlist_id} position={index} key={`app_setting` + index}>
      <IndexTable.Cell className="table_app_cellMinWidth">
        <Link onClick={() => {}}>
          <InlineGrid columns={['oneThird', 'twoThirds']} gap={'100'} alignItems="center">
            <Avatar size="lg" customer name={user.display_name} initials={String(user.display_name || 'U').charAt(0)} source={user.user_avatar ? helpers.getMediaLink(user.user_avatar) : 'https://placehold.co/100x100'} />
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {user.display_name}
            </Text>
          </InlineGrid>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <InlineStack gap={'100'} align="start" blockAlign="center">
          <Thumbnail size="small" source={helpers.getMediaLink(product.product_thumbnail_to_media ? product.product_thumbnail_to_media.media_thumbnail ? product.product_thumbnail_to_media.media_thumbnail['128x128'] : '' : undefined)} alt={''} />
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {product.product_name}
          </Text>
        </InlineStack>
      </IndexTable.Cell>
      <IndexTable.Cell>{product_variant?.variant_name ?? '-'}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY')}</IndexTable.Cell>
    </IndexTable.Row>);
  return <>
      <Helmet>
        <title>Sản phẩm yêu thích</title>
      </Helmet>
      <Page title="Danh sách yêu thích" subtitle="Danh sách yêu thích của người dùng">
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
          <IndexTable resourceName={{
          singular: 'Wishlist',
          plural: 'Wishlist'
        }} itemCount={totalItems || 1} headings={[{
          title: 'Tài khoản'
        }, {
          title: 'Sản phẩm'
        }, {
          title: 'Phiên bản'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {records?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}