import { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockStack, Card, Page, Tabs, Text, TextField } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { PresentList } from './component/present.component';
import WinnerList from './component/winner.list.component';
import SettingLuckyWGame from './component/present.setting.component';
import { useClearGameLuckyWheelSession, useGetGame } from 'queries/game.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
// import QuickSearchWallet from 'components/quickSearchWallet';

export default function GameViewLuckyWheel() {
  // const loading = useAppSelector((state) => state.game.loading);
  // const updating = useAppSelector((state) => state.game.updating);

  const {
    data: entity
  } = useGetGame('lucky_wheel');
  const {
    mutateAsync: clearGameLuckyWheelSession
  } = useClearGameLuckyWheelSession();
  const history = useNavigate();
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setTabselected(selectedTabIndex);
  }, []);
  const tabs = useMemo(() => [{
    id: 'tab_0',
    content: 'Danh sách nhận thưởng',
    panelID: 'top_10'
  }, {
    id: 'tab_1',
    content: 'Danh sách quà tặng',
    panelID: 'list_present'
  }, {
    id: 'tab_2',
    content: 'Cài đặt',
    panelID: 'setting'
  }, {
    id: 'tab_3',
    content: 'Mã nhúng',
    panelID: 'embed_code'
  }], []); // Empty dependency array means this will only run once on mount

  const EmbedCode = useCallback(() => {
    return <Card>
        <BlockStack gap="400">
          <Text as="p" fontWeight="bold">
            Yêu cầu chung khi tham gia game này: Người dùng phải đăng nhập.
          </Text>

          <Text as="p">
            Trước tiên, vòng quay may mắn có thể quay tới bất kỳ món quà gì, cho nên endpoint này sẽ xác định quà gì người này nhận được:
          </Text>

          <TextField value="POST {{backend_url}}/game/lucky_wheel/set_my_presents" selectTextOnFocus label="" readOnly autoComplete="off" />

          <Text as="p">Để lấy bảng quà tặng của bản thân, truy vấn như sau:</Text>
          <TextField value="GET {{backend_url}}/game/lucky_wheel/my_present" selectTextOnFocus label="" readOnly autoComplete="off" />

          <Text as="p">Để lấy bảng quà tặng của tất cả, truy vấn như sau:</Text>
          <TextField value="GET {{backend_url}}/game/lucky_wheel/list" selectTextOnFocus label="" readOnly autoComplete="off" />
        </BlockStack>
      </Card>;
  }, []);
  const [clearingData, setClearingData] = useState(false);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa dữ liệu?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      setClearingData(true);
      await clearGameLuckyWheelSession();
      await helpers.sleep(1000);
      setClearingData(false);
    }
  }, []);
  return <>
      <Helmet>
        <title>Vòng quay may mắn</title>
      </Helmet>

      <Page title={entity?.game_title} backAction={{
      content: 'Quay lại',
      onAction: () => history('/game')
    }} primaryAction={{
      loading: clearingData,
      icon: DeleteIcon,
      content: 'Clear session Data',
      onAction: handleDelete
    }}>
        <div className="custom_tabs" style={{
        margin: '0 -15px'
      }}>
          <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
          <div style={{
          margin: '15px'
        }}>
            {tabs[tabselected].panelID === 'top_10' && <WinnerList />}
            {tabs[tabselected].panelID === 'list_present' && <PresentList />}
            {tabs[tabselected].panelID === 'setting' && <SettingLuckyWGame entity={entity} />}
            {tabs[tabselected].panelID === 'embed_code' && <EmbedCode />}
          </div>
        </div>
        <br />
        <br />
        <br />
      </Page>
    </>;
}