import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import helpers from 'helpers/index';
import { TypedNotification } from 'interface/notification.model';
import queryClient, { IQueryParams } from 'queries';


export const ALLOWED_NOTIFICATION_DATA_KEY = [
    'NAVIGATION_SEARCH_PRODUCT_SCREEN',
    // 'NAVIGATION_HOME_SCREEN',
    'NAVIGATION_OPEN_EXTERNAL_LINK',
    'NAVIGATION_ALL_STEPS_PARTNER_GUIDE_SCREEN',
    'NAVIGATION_DETAIL_NEWS_SCREEN',
    'NAVIGATION_REVENUE_RANKING_SCREEN',
    'NAVIGATION_COIN_RANKING_SCREEN',
    'NAVIGATION_LUCKY_WHEEL_SCREEN',
    // 'NAVIGATION_DAILY_GIFT_SCREEN',
    // 'NAVIGATION_WITH_DRAW_SCREEN',
    // 'NAVIGATION_TRANSACTIONS_LIST_SCREEN',
    // 'NAVIGATION_HISTORY_WITH_DRAW_SCREEN',
    // 'NAVIGATION_PAYMENTS_INFO_SCREEN',
    // 'NAVIGATION_ADD_PAYMENT_METHOD_INFO_SCREEN',
    // 'NAVIGATION_DELETE_ACCOUNT_SETTING_SCREEN',
    // 'NAVIGATION_CREATE_ADDRESS_SCREEN',
    'NAVIGATION_PAYMENT_BANKING_ORDER_SCREEN',
    // 'NAVIGATION_LOGIN_SCREEN',
    // 'NAVIGATION_FORGOT_PASSWORD_SCREEN',
    // 'NAVIGATION_REGISTER_ACCOUNT_SCREEN',
    // 'NAVIGATION_SET_PASSWORD_SCREEN',
    // 'NAVIGATION_REFERRAL_CODE_REGISTER_SCREEN',
    // 'NAVIGATION_SUCCESS_SCREEN',
    // 'NAVIGATION_VERIFY_OTP_SCREEN',
    // 'NAVIGATION_PARTNER_REGISTER_SCREEN',
    // 'NAVIGATION_PLAN_REGISTER_SCREEN',
    // 'NAVIGATION_PAYMENT_PLAN_REGISTER_SCREEN',
    // 'NAVIGATION_SYSTEM_AFFILIATE_SCREEN',
    'NAVIGATION_DETAIL_VOUCHER_SCREEN',
    'NAVIGATION_DETAIL_PRODUCT_SCREEN',
    // 'NAVIGATION_LIST_POLICY_SCREEN',
    'NAVIGATION_DETAIL_BANNER_SCREEN',
    // 'NAVIGATION_SEARCH_ORDER_SCREEN',
    // 'NAVIGATION_SWAP_GIFT_REWARD_SCREEN',
    'NAVIGATION_REWARD_SCREEN',
    // 'NAVIGATION_ALL_ORDERS_SCREEN',
    // 'NAVIGATION_ALL_NOTIFICATIONS_SCREEN',
    // 'NAVIGATION_STATISTICS_SCREEN',
    // 'NAVIGATION_ACCOUNT_SCREEN',
    'NAVIGATION_GUIDE_AFFILIATE_SCREEN',
    // 'NAVIGATION_PROFILE_SETTING_SCREEN',
    // 'NAVIGATION_BASIC_INFO_SETTING_SCREEN',
    // 'NAVIGATION_LIST_CONTACT_FORM_SCREEN',
    // 'NAVIGATION_ALL_VOUCHER_SCREEN',
    'NAVIGATION_DETAIL_CONTACT_FORM_SCREEN',
    // 'NAVIGATION_CREATE_CONTACT_FORM_SCREEN',
    // 'NAVIGATION_CHANGE_PASSWORD_SETTING_SCREEN',
    // 'NAVIGATION_ALL_SETTING_SCREEN',
    // 'NAVIGATION_FAVORITE_PRODUCT_SCREEN',
    // 'NAVIGATION_REBUY_PRODUCT_SCREEN',
    // 'NAVIGATION_LIST_PRODUCT_SCREEN',
    'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY',
    'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION',
    'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND',
    'NAVIGATION_CART_PRODUCT_SCREEN',
    // 'NAVIGATION_CREATE_ORDER_SCREEN',
    'NAVIGATION_DETAIL_ORDER_SCREEN',
    'NAVIGATION_DETAIL_SHIPMENT_SCREEN',
    // 'NAVIGATION_LIST_NEWS_SCREEN',
    // 'NAVIGATION_SEARCH_NEWS_SCREEN',
    // 'NAVIGATION_ALL_NEWS_SCREEN',
    // 'NAVIGATION_HISTORY_REQUEST_GIFT_SCREEN',
    'NAVIGATION_DETAIL_REQUEST_GIFT_SCREEN',
    'NAVIGATION_TOP_PRODUCT_SCREEN',
    // 'NAVIGATION_DETAIL_POLICY_SCREEN',
    'NAVIGATION_COIN_WALLET_SCREEN',
    'NAVIGATION_CASH_WALLET_SCREEN',
    // 'NAVIGATION_LIBRARY_SCREEN',
    'NAVIGATION_DETAIL_POST_LIBRARY_SCREEN',
    // 'NAVIGATION_ALL_SHARE_POST_SCREEN',
    // 'NAVIGATION_LIST_REVIEW_SCREEN',
    // 'NAVIGATION_LIST_BRAND_SCREEN',
    'NAVIGATION_DETAIL_WITH_DRAW_SCREEN',
    'NAVIGATION_CREATE_REVIEW_ORDER_SCREEN',
    'NAVIGATION_RANK_ACCOUNT_SCREEN'
];

export const ALLOWED_VOUCHER_DATA_KEY = [
    'NAVIGATION_SEARCH_PRODUCT_SCREEN',
    'NAVIGATION_OPEN_EXTERNAL_LINK',
    'NAVIGATION_DETAIL_PRODUCT_SCREEN',
    'NAVIGATION_DETAIL_BANNER_SCREEN',
    'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY',
    'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION',
    'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND',
    'NAVIGATION_CART_PRODUCT_SCREEN',
    'NAVIGATION_TOP_PRODUCT_SCREEN',
    'NAVIGATION_DETAIL_POST_LIBRARY_SCREEN',
];

export const SUGGEST_NOTIFICATION_DATA_KEY = [
    'NAVIGATION_DETAIL_VOUCHER_SCREEN',
    'NAVIGATION_DETAIL_PRODUCT_SCREEN',
    'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY',
    'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION',
    'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND',
    'NAVIGATION_DETAIL_POLICY_SCREEN',
    'NAVIGATION_DETAIL_NEWS_SCREEN',
];


export const DEEPLINK_SCREEN_NAMES = [
  'NAVIGATION_ALL_VOUCHER_SCREEN',
  'NAVIGATION_DETAIL_VOUCHER_SCREEN',

  'NAVIGATION_DETAIL_PRODUCT_SCREEN',
  'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY',
  'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION',
  'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND',
  'NAVIGATION_CART_PRODUCT_SCREEN',
  'NAVIGATION_TOP_PRODUCT_SCREEN',
  'NAVIGATION_FAVORITE_PRODUCT_SCREEN',
  'NAVIGATION_REBUY_PRODUCT_SCREEN',

  'NAVIGATION_REWARD_SCREEN',
  'NAVIGATION_COIN_WALLET_SCREEN',
  'NAVIGATION_CASH_WALLET_SCREEN',
  'NAVIGATION_REVENUE_RANKING_SCREEN',
  'NAVIGATION_COIN_RANKING_SCREEN',

  'NAVIGATION_LIST_POLICY_SCREEN',
  'NAVIGATION_DETAIL_POLICY_SCREEN',

  'NAVIGATION_LIST_CONTACT_FORM_SCREEN',
  'NAVIGATION_GUIDE_AFFILIATE_SCREEN',
  'NAVIGATION_SYSTEM_AFFILIATE_SCREEN',

  'NAVIGATION_SEARCH_PRODUCT_SCREEN',
  'NAVIGATION_HOME_SCREEN',

  'NAVIGATION_LIST_NEWS_SCREEN',
  'NAVIGATION_ALL_NEWS_SCREEN',
  'NAVIGATION_DETAIL_NEWS_SCREEN',

  'NAVIGATION_LIBRARY_SCREEN',
  // 'NAVIGATION_DETAIL_POST_LIBRARY_SCREEN',

  'NAVIGATION_LUCKY_WHEEL_SCREEN',
  'NAVIGATION_DAILY_GIFT_SCREEN',
  'NAVIGATION_ALL_STEPS_PARTNER_GUIDE_SCREEN',

  'NAVIGATION_STATISTICS_SCREEN',
  // 'NAVIGATION_ALL_ORDERS_SCREEN',
  // 'NAVIGATION_LIST_NOTIFICATIONS_SCREEN',
  'NAVIGATION_DETAIL_BANNER_SCREEN',

  'NAVIGATION_ACCOUNT_SCREEN',
  'NAVIGATION_RANK_ACCOUNT_SCREEN'
];


export const SCREEN_PATHS = {
  NAVIGATION_ALL_VOUCHER_SCREEN: 'all-vouchers',
  NAVIGATION_DETAIL_PRODUCT_SCREEN: 'product/{params}',
  NAVIGATION_PLAN_REGISTER_SCREEN: 'plan-register',
  NAVIGATION_SYSTEM_AFFILIATE_SCREEN: 'system-affiliate',
  NAVIGATION_DETAIL_VOUCHER_SCREEN: 'voucher/{params}',
  NAVIGATION_LIST_POLICY_SCREEN: 'policies',
  // NAVIGATION_DETAIL_BANNER_SCREEN: 'banner/{params}',
  NAVIGATION_SEARCH_PRODUCT_SCREEN: 'search-product',
  NAVIGATION_REWARD_SCREEN: 'rewards',
  // NAVIGATION_LIST_NOTIFICATIONS_SCREEN: 'notifications',
  NAVIGATION_GUIDE_AFFILIATE_SCREEN: 'affiliate-guide',
  NAVIGATION_LIST_CONTACT_FORM_SCREEN: 'contact-forms',
  NAVIGATION_FAVORITE_PRODUCT_SCREEN: 'favorite-products',
  NAVIGATION_REBUY_PRODUCT_SCREEN: 'rebuy-products',
  NAVIGATION_RANK_ACCOUNT_SCREEN: 'rank-account',
  NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY:'product-list/{params}',
  NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION:'product-list/{params}',
  NAVIGATION_LIST_PRODUCT_SCREEN_BRAND:'product-list/{params}',
  NAVIGATION_LIST_BRAND_SCREEN: 'brands',
  NAVIGATION_CART_PRODUCT_SCREEN: 'cart',
  NAVIGATION_LIST_NEWS_SCREEN: 'news',
  NAVIGATION_ALL_NEWS_SCREEN: 'all-news',
  NAVIGATION_ALL_STEPS_PARTNER_GUIDE_SCREEN: 'partner-guide',
  NAVIGATION_DETAIL_NEWS_SCREEN: 'news-detail/{params}',
  NAVIGATION_REVENUE_RANKING_SCREEN: 'revenue-ranking',
  NAVIGATION_COIN_RANKING_SCREEN: 'coin-ranking',
  NAVIGATION_LUCKY_WHEEL_SCREEN: 'lucky-wheel',
  NAVIGATION_DAILY_GIFT_SCREEN: 'daily-gift',
  NAVIGATION_CASH_WALLET_SCREEN: 'cash-wallet',
  NAVIGATION_COIN_WALLET_SCREEN: 'coin-wallet',
  NAVIGATION_DETAIL_POST_LIBRARY_SCREEN: 'post-library/{params}',
  NAVIGATION_DETAIL_POLICY_SCREEN: 'policy-detail/{params}',
  NAVIGATION_TOP_PRODUCT_SCREEN: 'top-products',
  NAVIGATION_HOME_SCREEN: 'home',
  NAVIGATION_LIBRARY_SCREEN: 'library',
  NAVIGATION_STATISTICS_SCREEN: 'statistics',
  NAVIGATION_ALL_ORDERS_SCREEN: 'orders',
  NAVIGATION_ACCOUNT_SCREEN: 'account',
};

type IQuery = TypedNotification & IQueryParams;


/*
* List of all notification
Cá nhân ...
*/
export function useGetMyNotifications(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["notification/fetch_entities"],
        queryFn: () => axios.get<TypedNotification[]>(`/notification/my_notification${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}



export function useGetNotifications(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["notification/fetch_entities"],
        queryFn: () => axios.get<TypedNotification[]>(`/notification${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


export function useGetNotifitcation() {
    return useMutation({
        mutationKey: ['notification/fetch_entity'],
        mutationFn: (notification_id: string) => axios.get<TypedNotification>(`/notification/${notification_id}`).then((res) => res.data),
    });
}

export function useCreateNotification() {
    return useMutation({
        mutationKey: ['notification/create_entity'],
        mutationFn: (entity: TypedNotification) => axios.post<TypedNotification>(`/notification/`, helpers.cleanEntity(entity)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification/fetch_entities'] });
        }
    });
}

/** Gửi lại tin */
export function useResendNotifitcation() {
    return useMutation({
        mutationKey: ['notification/resend'],
        mutationFn: (notification_id: string) => axios.post<any>(`/notification/resend/${notification_id}`).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification/fetch_entities'] });
        }
    });
}

/** Gửi lại tin */
export function useCancelsendNotifitcation() {
    return useMutation({
        mutationKey: ['notification/cancel_send'],
        mutationFn: (notification_id: string) => axios.delete<any>(`/notification/cancel_send/${notification_id}`).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notification/fetch_entities'] });
        }
    });
}
