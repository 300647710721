/**
 * Page
 */

import { Page, Layout, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, Card } from '@shopify/polaris';
import React, { useEffect } from 'react';
import Theme404 from '../../layout/404';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import dateandtime from 'date-and-time';
import { useGetPost } from 'queries/posts.query';

/*
react-router-dom:
let useParam =  {} as any;
    useParam = useParams();
    let Param = useParam.any || false;
    or
console.log(props.match.params)
*/

export default function ViewPage() {
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let {
    post_slug
  } = useParams();
  const {
    data,
    isLoading: loading,
    isError
  } = useGetPost(post_slug);
  const pageMeta = data ? `Đăng bởi ${data?.author?.display_name}` + (data?.createdAt && ` - last update at ${dateandtime.format(new Date(Number(data?.createdAt)), 'HH:mm:ss DD-MM-YYYY')}`) : null;
  const actual_page = <Page title={data?.post_title || ''} subtitle={pageMeta}>
      {loading ? <SkeletonPage>
          <Layout>
            <Layout.Section>
              <Card padding={'0'}>
                <SkeletonDisplayText size="medium" />
                <SkeletonBodyText lines={9} />
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage> : data?.post_id ? <Layout>
          <Layout.Section>
            <Card>{Parser(data?.post_content ?? ' ')}</Card>
          </Layout.Section>
        </Layout> : <Theme404 />}
    </Page>;
  return isError ? <Theme404 /> : <div id="general_page">{actual_page}</div>;
}