import { Navigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

/**
 * Những router được bảo vệ sẽ check đăng nhập hay chưa, nếu chưa đăng nhập thì chuyển hướng sang trang đăng nhập
 * @param param0
 * @returns
 */
const ProtectedRoute = ({
  children
}: any) => {
  const {
    isAuthenticated
  } = useAuth();
  if (!isAuthenticated) {
    let current_url = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?redirect_to=${current_url}`} replace />;
  } else {
    return children;
  }
};
export default ProtectedRoute;