import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActionList, Button, Card, DataTable, Divider, Icon, InlineGrid, InlineStack, Link, Page, Popover, SkeletonTabs, Text, Thumbnail, Tooltip, useIndexResourceState } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon, PinIcon, PinFilledIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProductCategoryModal from './product_category.edit.modal';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useDeleteProductCategory, useGetProductCategory, useUpdateProductCategory } from 'queries/product_category.query';
import { TypedProduct_to_category, useBatchReorder, useCreateProductToCategory, useGetProductToCategories, usePinProductToTopCategory, useRemovePinProductToTopCategory } from 'queries/product_to_category.query';
import { useNotification } from 'context/NotificationContext';
import { Helmet } from 'react-helmet-async';
export default function ProductCategoryView() {
  let {
    category_id
  } = useParams();
  const {
    mutateAsync: pinMe
  } = usePinProductToTopCategory();
  const {
    mutateAsync: unpinMe
  } = useRemovePinProductToTopCategory();
  const {
    addNotification
  } = useNotification();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductCategory(category_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductCategory();
  const {
    mutateAsync: updateEntity
  } = useUpdateProductCategory();
  const modalEntity = useRef(null);
  /** relationship */
  const {
    data: productToCategoryData,
    refetch: getRelationship,
    isLoading: loading_relationship
  } = useGetProductToCategories({
    limit: 100,
    category_id: String(category_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship,
    isSuccess: addProductToCategorySuccessful
  } = useCreateProductToCategory();
  const [entities, setEntities] = useState<TypedProduct_to_category[]>([]);
  useEffect(() => {
    if (addProductToCategorySuccessful) addNotification('info', 'Thêm sản phẩm vào chuyên mục thành công.');
  }, [addProductToCategorySuccessful]);
  useEffect(() => {
    if (productToCategoryData) {
      let {
        entities
      } = productToCategoryData;
      setEntities(entities);
    }
  }, [productToCategoryData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async category_id => {
    const confirmed = await showDeleteConfirm('Xóa danh mục bài đăng này?', 'Chỉ các danh mục không có bài đăng nào mới có thể xóa bỏ. Nếu danh mục còn bài đăng, vui lòng di chuyển sang danh mục khác trước.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await deleteEntity(category_id);
        history('/product_category');
      } catch (e) {}
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const quickUpdateSetting = useCallback(async (status: number) => {
    setUpdateLoading(true);
    setActiveQuickUpdate(false);
    await updateEntity({
      category_id: entity.category_id,
      category_status: status
    });
    setUpdateLoading(false);
  }, [entity]);
  const {
    mutate: batchReorder
  } = useBatchReorder();
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    let cloneEntities = [...entities, ...[]];
    const [reorderedItem] = cloneEntities.splice(result.source.index, 1);
    cloneEntities.splice(result.destination.index, 0, reorderedItem);
    setEntities(cloneEntities);
    let reorderData = {};
    let index = 0;
    for (let d of cloneEntities) {
      index++;
      reorderData = {
        ...reorderData,
        ...{
          [d.id]: index
        }
      };
    }
    batchReorder(reorderData);
  }, [entities]);
  const DisplayListOfProduct = () => <Card padding="0">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="Polaris-DataTable__ScrollContainer">
          <Droppable droppableId="table">
            {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                <thead>
                  <tr>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">STT</th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Tên sản phẩm
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Tình trạng
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                      Giá bán
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric Polaris-DataTable__Cell--header">
                      Giá gốc
                    </th>
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric">
                      -
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {entities?.map(({
                product,
                pin_to_top,
                product_to_category_order,
                id
              }, index) => {
                const {
                  product_id,
                  product_name,
                  product_price,
                  product_original_price,
                  product_status,
                  product_thumbnail_to_media
                } = product;
                return <Draggable key={id} draggableId={id} index={index}>
                        {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              <InlineStack align="start" blockAlign="center" gap={'400'}>
                                <Thumbnail size="small" source={helpers.getThumbnailURI(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail : undefined, '128x128', '128x128')} alt={'product thumbnail: ' + product_name} />
                                <Button variant="plain" url={'/product/edit/' + product_id} icon={pin_to_top && PinIcon}>
                                  {product_name}
                                </Button>
                              </InlineStack>
                            </td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{product_status}</td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {helpers.formatNumber(product_price, ',')}
                            </td>

                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {helpers.formatNumber(product_original_price, ',')}
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                              {pin_to_top ? <Tooltip content="Bỏ ghim sản phẩm">
                                  <Button variant="plain" icon={PinFilledIcon} onClick={() => {
                          unpinMe(id);
                        }}></Button>
                                </Tooltip> : <Tooltip content="Ghim sản phẩm lên đầu">
                                  <Button variant="plain" icon={PinIcon} onClick={() => {
                          pinMe(id);
                        }}></Button>
                                </Tooltip>}
                            </td>
                          </tr>}
                      </Draggable>;
              })}
                  {provided.placeholder}
                </tbody>
              </table>}
          </Droppable>
        </div>
      </DragDropContext>
    </Card>;
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.category_name} subtitle={entity.category_excerpt} titleMetadata={<InlineStack gap="200">
          <Popover active={activeQuickUpdate} activator={<Button loading={updateLoading} variant="tertiary" disclosure={'down'} onClick={() => setActiveQuickUpdate(true)}>
                {entity.category_status === 1 ? 'Hiển thị' : 'Không hiển thị'}
              </Button>} autofocusTarget="first-node" onClose={() => setActiveQuickUpdate(false)}>
            <ActionList actionRole="menuitem" items={[{
        content: 'Hiển thị',
        onAction: () => quickUpdateSetting(1)
      }, {
        content: 'Không hiển thị',
        onAction: () => quickUpdateSetting(0)
      }]} />
          </Popover>
        </InlineStack>} backAction={{
    content: 'Back',
    onAction: () => history('/product_category')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa danh mục',
    onAction: () => handleDelete(entity?.category_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => {
      modalEntity.current = entity;
      setShowEditModal(true);
    }
  }]}>
      <div className="content-wrap">{Parser(entity?.category_description ?? '')}</div>
      <br />
      <Divider />
      <br />

      {loading_relationship ? <SkeletonTabs /> : Array.isArray(entities) && entities.length < 1 ? <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào danh mục.
        </Text> : <DisplayListOfProduct />}
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    if (product_id) {
      createRelationship({
        category_id: String(category_id),
        product_id: String(product_id)
      });
    }
    setShowProductModal(false);
  }, [category_id]);
  return <>
      <Helmet>
        <title>{entity?.category_name}</title>
      </Helmet>
      <Capacity current_user_can={['manage_products', 'view_products', 'edit_products']} onFail={<>
            <div>You are not allowed to do anything here.</div>
          </>}>
        <ProductCategoryModal show={showEditModal} onClose={closeEditModal} entity={modalEntity.current} />
        <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
        {loading ? <SkeletonLoading /> : actualPage}

        <br />
        <br />
        <br />
        <br />
        <br />
      </Capacity>
    </>;
}