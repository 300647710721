import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserVerifiedRequest, useGetUserSingleVerified, useDeleteUserVerifiedRequest, useUpdateUserVerified, TypedUser_verified } from 'queries/user_verified.query';
import { DescriptionList, InlineGrid, Page, Image, Link, ButtonGroup, Button, Text } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { Helmet } from 'react-helmet-async';
import dateandtime from 'date-and-time';
export default function UserVerifiedView() {
  let {
    user_verified_id
  } = useParams();
  const history = useNavigate();
  const {
    refetch: getEntity
  } = useGetUserSingleVerified(user_verified_id);
  const {
    mutateAsync: updateEntity
  } = useUpdateUserVerified();
  const [entity, setEntity] = useState<TypedUser_verified>(null);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const note = JSON.parse(entity?.user_note || "{}");
  useEffect(() => {
    getEntity().then(({
      data
    }) => {
      setEntity(data);
      setActiveButtonIndex(data?.verified_status ?? 0);
    }).catch(e => {});
  }, []);
  const handleButtonClick = useCallback(async (index: number) => {
    await updateEntity({
      id: user_verified_id,
      verified_status: index
    }).catch(e => {});
    setActiveButtonIndex(index);
  }, [activeButtonIndex]);
  return <Page narrowWidth title="Yêu cầu chứng thực" backAction={{
    content: 'Quay lại danh sách',
    onAction: () => history('/user_verified')
  }}>
      <Helmet>
        <title>Yêu cầu chứng thực</title>
      </Helmet>
      <DescriptionList items={[{
      term: 'Người yêu cầu',
      description: <>
                <Text as="p">
                 <span style={{
            fontWeight: 'bold'
          }}>Tên:</span> {entity?.user?.display_name ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Số điện thoại:</span> {entity?.user?.user_phonenumber ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Email:</span> {entity?.user?.user_email ?? '______'}
                </Text>
                <Text as="p">
                  <Link url={`/customer/view/` + entity?.user_id + '#tab-4'}>Xem hồ sơ</Link>
                </Text>
              </>
    }, {
      term: 'Hình ảnh đăng tải',
      description: <>
                <InlineGrid gap="400" columns={{
          xs: 1,
          md: 1
        }}>
                  <Link url={__helpers.getMediaLink(entity?.cccd_front_image)} target="_blank">
                    <Image width={'300px'} height={'300px'} source={__helpers.getMediaLink(entity?.cccd_front_image)} alt={'ảnh CCCD mặt trước'} style={{
              objectFit: 'contain'
            }} />
                  </Link>
                  <Link url={__helpers.getMediaLink(entity?.cccd_back_image)} target="_blank">
                    <Image width={'300px'} height={'300px'} source={__helpers.getMediaLink(entity?.cccd_back_image)} alt={'ảnh CCCD mặt sau'} style={{
              objectFit: 'contain'
            }} />
                  </Link>
                  {/*<Link url={__helpers.getMediaLink(entity?.user_face_image)} target="_blank">*/}
                  {/*  <Image width={'300px'} height={'300px'} source={__helpers.getMediaLink(entity?.user_face_image)} alt={'ảnh người chụp'} />*/}
                  {/*</Link>*/}
                </InlineGrid>
                <br />
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>CCCD:</span> {entity?.cccd_id ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Họ và tên:</span> {note?.name ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Ngày sinh:</span> {dateandtime.format(new Date(note?.dob), 'DD-MM-YYYY') ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Thường trú:</span> {note?.permanent_address ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Giới tính:</span> {note?.gender ?? '______'}
                </Text>
                <Text as="p">
                  <span style={{
            fontWeight: 'bold'
          }}>Số CMND cũ:</span> {note?.old_id_number ?? '______'}
                </Text>
              </>
    }, {
      term: 'Kết quả xét duyệt',
      description: <>
                <ButtonGroup variant="segmented">
                  <Button pressed={activeButtonIndex === 1} onClick={() => handleButtonClick(1)}>
                    Duyệt
                  </Button>
                  <Button pressed={activeButtonIndex === -1} onClick={() => handleButtonClick(-1)}>
                    Từ chối duyệt
                  </Button>
                </ButtonGroup>
                <br />
                {entity?.updatedBy && <>
                    Cập nhật lần cuối {__helpers.subtractTimeHistory(entity?.updatedAt)} bởi{' '}
                    {entity?.confirmer?.display_name ?? entity?.confirmer?.user_email ?? entity?.confirmer?.user_phonenumber ?? '-'}
                  </>}
              </>
    }]} />
    </Page>;
}