import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import queryClient from 'queries';



/*
* List of all app
*/
export function useGetServerInfo() {
    return useQuery({
        queryKey: ["utils/server_info"],
        queryFn: () => axios.get<any[]>(`/utils/`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}
