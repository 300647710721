import { BlockStack, Card, DataTable, Divider, EmptyState, InlineStack, Layout, Link, Modal, Page, Text } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emptyIMG from 'media/images/empty.png';
import Pagination from 'components/pagination';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { useGetDevices } from 'queries/device.query';
import dateandtime from 'date-and-time';
import { ___ } from 'languages/index';
import { NoteIcon } from '@shopify/polaris-icons';
export default function DeviceList() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search: searchParam
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(searchParam);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetDevices(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (searchParam !== buildURLSearch) history('/devices' + buildURLSearch);
    getEntities();
  }, [mainQuery]);
  const [deviceDetail, setDeviceDetail] = useState<any>(null);
  const closeModal = useCallback(() => {
    setDeviceDetail(null);
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const detailModal = <Modal size="large" open={deviceDetail !== null} onClose={closeModal} title="Chi tiết thiết bị" primaryAction={{
    content: 'Đóng',
    onAction: closeModal
  }}>
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <Text as="h4" variant="headingLg">
              Chi tiết thiết bị
            </Text>
            <br />
            <div style={{
            paddingRight: '20px',
            borderRight: '1px solid #eee'
          }}>
              <BlockStack gap={'100'}>
                <InlineStack align="space-between">
                  <Text as="p">ID</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.device_id}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Mã thiết bị</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.device_uuid}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">User Agent</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.user_agent}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Loại thiết bị</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.system_type}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Trạng thái</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.status === 1 ? 'Hoạt động' : 'Không hoạt động'}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Ngày tạo</Text>
                  <Text as="p" fontWeight="bold">
                    {dateandtime.format(new Date(Number(deviceDetail?.createdAt)), 'HH:mm:ss DD-MM-YYYY')}
                  </Text>
                </InlineStack>
                <InlineStack align="space-between">
                  <Text as="p">Số lượng người dùng đăng nhập chung</Text>
                  <Text as="p" fontWeight="bold">
                    {deviceDetail?.user_to_device?.length || 0}
                  </Text>
                </InlineStack>

                <br />
                <Divider />
                <div className="small-icon">
                  <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                    <NoteIcon /> Thông tin mới nhất của thiết bị
                  </Text>
                </div>
                <br />

                {deviceDetail?.device_debug_info && Object.entries(JSON.parse(deviceDetail?.device_debug_info || '{}')).map(([key, value]) => <InlineStack align="space-between" key={key}>
                      <Text as="p">{key}</Text>
                      <Text as="p" fontWeight="bold">
                        {JSON.stringify(value)}
                      </Text>
                    </InlineStack>)}
              </BlockStack>
            </div>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <Text as="h4" variant="headingLg">
              Người đã đăng nhập
            </Text>
            <br />
            {deviceDetail?.user_to_device?.length > 0 ? <>
                <DataTable columnContentTypes={['text', 'text', 'text']} headings={['Tên', 'SDT', 'Email']} rows={deviceDetail?.user_to_device?.map(userData => {
              const {
                display_name,
                user_id,
                user_email,
                user_phonenumber
              } = userData?.user || {};
              return [<Link url={'/customer/view/' + user_id} target="_blank">
                        {display_name}
                      </Link>, user_phonenumber, user_email];
            })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />
              </> : <Text as="p"> Chưa có ai đăng nhập từ thiết bị này</Text>}
          </Layout.Section>
        </Layout>
      </Modal.Section>
    </Modal>;
  const emptyData = <EmptyState heading="Không có thiết bị nào!" image={emptyIMG}>
      <p>Oh, Không có gì ở đây. Hãy thử bỏ bộ lọc hoặc tạo mới.</p>
    </EmptyState>;
  const renderItem = (props: any) => {
    const {
      device_id,
      device_uuid,
      system_type,
      status,
      createdAt
    } = props;
    return [<div className="clickable" key={device_id} onClick={() => setDeviceDetail(props)}>
        {device_uuid}
      </div>, system_type, status === 1 ? 'Hoạt động' : 'Không hoạt động', dateandtime.format(new Date(Number(createdAt)), 'HH:mm:ss DD-MM-YYYY')];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable sortable={[false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text']} headings={['Mã thiết bị', 'Loại thiết bị', 'Trạng thái', 'Ngày tạo']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <Page fullWidth title="Thiết bị" subtitle="Quản lý các thiết bị đã truy cập vào hệ thống (Vào bản web và tải app trên điện thoại).">
      {detailModal}
      <Layout>
        <Layout.Section>
          <Card padding="0">
            <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
            label: 'Bản Web',
            value: 'website',
            field: 'system_type'
          }, {
            label: 'Android',
            value: 'android',
            field: 'system_type'
          }, {
            label: 'IOS',
            value: 'ios',
            field: 'system_type'
          }]} sortField={[]} />
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Layout.Section>
      </Layout>
    </Page>;
  return <>{entities === null ? <SkeletonLoading /> : Actual_page}</>;
}