import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/


export interface TypedProduct_to_collection {
    id?: string;
    product_id?: string;
    collection_id?: string;
    createdAt?: string;

    product?: TypedProduct
    product_to_collection_order?: number;
    pin_to_top?: number;
}


type IQuery = TypedProduct_to_collection & IQueryParams;


/*
* List of all app
*/
export function useGetProductToCollections(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_to_collection/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct_to_collection[]>(`/product_to_collection${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetProductToCollection(app_id: string) {
    return useQuery({
        queryKey: ['product_to_collection/fetch_entity'],
        queryFn: () => axios.get<TypedProduct_to_collection>(`/product_to_collection/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useCreateProductToCollecton() {
    return useMutation({
        mutationKey: ['product_to_collection/create_entity'],
        mutationFn: (entity: TypedProduct_to_collection) => axios.post<TypedProduct_to_collection>(`/product_to_collection`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_to_collection/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductToCollection() {
    return useMutation({
        mutationKey: ['product_to_collection/delete_entity'],
        mutationFn: ({ collection_id, product_id }: { collection_id: string, product_id: string }) => axios.delete<any>(`/product_to_collection/bulk_delete`, {
            data: { collection_id, product_id }
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_to_collection/fetch_entity_list'] });
        }

    });
}

export function usePinProductToTopCollection() {
  return useMutation({
    mutationKey: ['product_to_collection/pin_to_top'],
    mutationFn: (id: string) => axios.patch<any>(`/product_to_collection/pin_to_top/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['product_to_collection/fetch_entity_list'] });
    }
  });
}
export function useRemovePinProductToTopCollection() {
  return useMutation({
    mutationKey: ['product_to_collection/pin_to_top'],
    mutationFn: (id: string) => axios.delete<any>(`/product_to_collection/pin_to_top/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['product_to_collection/fetch_entity_list'] });
    }
  });
}


export function useBatchReorder() {
  return useMutation({
    mutationKey: ['product_to_collection/batch_reorder'],
    mutationFn: (reorderData: any) => axios.patch<any>(`/product_to_collection/batch_reorder`, helpers.cleanEntity(reorderData))
  });
}
