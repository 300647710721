import ContactformAssign from './contactform.assign';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Card, Page, BlockStack, Text, Divider, Link, Popover, ActionList, Button, InlineStack, Tabs, InlineGrid, DescriptionList, Badge, Thumbnail } from '@shopify/polaris';
import Comments from 'components/CommentsComponent';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import CustomerNew from 'entities/customer/customer.new';
import QuickSearchUser from 'components/quickSearchUser';
import ActivityTracing from '../../components/activityTracing';
import { Helmet } from 'react-helmet-async';
import { useGetContactform, useUpdateContactform } from 'queries/contactform.query';
import { useAssignUserToDeviceUUID } from 'queries/device.query';
import __ from 'languages/index';
export default function ContactFormView() {
  let {
    contactform_id
  } = useParams();
  const {
    mutate: getEntity,
    isPending,
    data: entity
  } = useGetContactform();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess
  } = useUpdateContactform();
  const {
    mutate: assignUserToDeviceUUID
  } = useAssignUserToDeviceUUID();
  const history = useNavigate();
  useEffect(() => {
    getEntity(contactform_id);
  }, [contactform_id, isSuccess]);
  const contactformCondition = useCallback((_status: number) => {
    switch (_status) {
      case 9:
        return 'Hủy';
      case 8:
        return 'Đang xử lý';
      case 7:
        return 'Đã xử lý xong';
      default:
        return 'Chưa xử lý';
    }
  }, []);

  /**
   * For button only ...
   */
  const [showPophoverStatus, setShowPophoverStatus] = useState(null);
  const togglePophoverStatusCallback = useCallback(async (contactform_id: any) => {
    await helpers.sleep(100);
    setShowPophoverStatus(contactform_id);
  }, []);
  const onClosePophoverStatusCallback = useCallback(() => {
    setShowPophoverStatus(null);
  }, []);
  const changeCampaignStatus = useCallback((contactform_id: any, new_status: number) => {
    onClosePophoverStatusCallback();
    updateCallback(contactform_id, new_status);
  }, []);
  const updateCallback = useCallback(async (contactform_id: any, new_status: number) => {
    updateEntity({
      contactform_id: contactform_id,
      contactform_status: Number(new_status)
    });
  }, []);
  const [showNewCustomerPopup, setShowNewCustomerPopup] = useState(false);
  const convertToCustomer = useCallback(() => {
    setShowNewCustomerPopup(true);
  }, []);
  const onCloseCustomerModal = useCallback(async (user_id: any) => {
    if (user_id) {
      await updateEntity({
        contactform_id: contactform_id,
        user_id: String(user_id)
      });
      assignUserToDeviceUUID({
        user_id: String(user_id),
        device_uuid: entity?.device_uuid
      });
    }
    setShowNewCustomerPopup(false);
  }, [entity]);
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const tabs = [{
    id: 'tab_1',
    content: 'Thảo luận',
    panelID: 'comment'
  }, {
    id: 'tab_3',
    content: 'Lịch sử',
    panelID: 'activity_trace'
  }];
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setTabselected(selectedTabIndex);
  }, []);
  return <>
      <Helmet>
        <title>{`[${entity?.contactform_category}] - ${entity?.contactform_title}`}</title>
      </Helmet>

      <CustomerNew onClose={userData => onCloseCustomerModal(userData?.user_id || null)} show={showNewCustomerPopup} defaultNumber={entity?.contactform_numberphone} defaultEmail={entity?.contactform_email} defaultName={entity?.contactform_name} />
      <Page title="Chi tiết một yêu cầu" backAction={{
      content: 'Back',
      onAction: () => history('/contactform')
    }}>
        <InlineGrid columns={{
        xs: '1',
        sm: '1',
        md: '1',
        lg: ['twoThirds', 'oneThird']
      }} gap={'400'}>
          <Card>
            <BlockStack gap={'400'}>
              <Text as="h1" variant="headingMd">
                {__(`contact_${entity?.contactform_category}_department`)} - {entity?.contactform_title}
              </Text>

              {entity?.updatedAt && <Text as="span" tone="subdued" variant="bodySm">
                  Cập nhật lần cuối bởi {entity?.updater?.display_name} lúc{' '}
                  {dateandtime.format(new Date(Number(entity?.updatedAt)), 'DD-MM-YYYY, HH:mm')}
                </Text>}

              <Divider />

              <Text as="p">{entity?.contactform_content}</Text>

              <InlineStack gap="400" align="start" blockAlign="center">
                {entity?.contactform_media && entity?.contactform_media.map(({
                media
              }) => {
                return <Link url={helpers.getMediaLink(media?.media_url)} target="_blank">
                        <Thumbnail alt="" source={media?.media_thumbnail ? helpers.getThumbnailURI(media?.media_thumbnail, '128x128', '128x128') : media?.media_url} />
                      </Link>;
              })}
              </InlineStack>
            </BlockStack>
            <br />
            <Divider />
            <br />
            <ContactformAssign entity={entity} readonly={entity?.contactform_status === 7} />
          </Card>
          <Card>
            {entity?.contactform_status === 7 ? <Text as="p">Đã xử lý xong</Text> : <Popover key={entity?.contactform_id} active={showPophoverStatus === entity?.contactform_id} activator={<InlineStack gap={'400'}>
                    <Text as="p">Tình trạng xử lý: </Text>
                    <Button variant="plain" disclosure loading={updating} onClick={() => togglePophoverStatusCallback(entity?.contactform_id)}>
                      {contactformCondition(entity?.contactform_status)}
                    </Button>
                  </InlineStack>} onClose={() => onClosePophoverStatusCallback()} ariaHaspopup={false}>
                <ActionList actionRole="menuitem" items={[{
              content: 'Đã xử lý xong',
              onAction: () => changeCampaignStatus(entity?.contactform_id, 7)
            }, {
              content: 'Đang xử lý',
              onAction: () => changeCampaignStatus(entity?.contactform_id, 8)
            }, {
              content: 'Hủy',
              onAction: () => changeCampaignStatus(entity?.contactform_id, 9)
            }]} />
              </Popover>}
            <br />
            <Divider />
            <br />
            {entity?.user?.display_name ? <>
                <Text as="span">
                  Được gửi bởi <Link url={'/customer/view/' + entity?.user?.user_id}>{entity?.user?.display_name}</Link>
                </Text>
                <br />
              </> : <Popover active={showAddnewUser} activator={<Button variant="plain" disclosure onClick={() => setShowAddnewUser(true)}>
                    Set khách hàng
                  </Button>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
                <Card>
                  <QuickSearchUser title="" user_role="customer" onClose={__newUser => {
                onCloseCustomerModal(String(__newUser.user_id));
                setShowAddnewUser(false);
              }} />
                </Card>
              </Popover>}
            <br />
            <Divider />
            <br />
            <DescriptionList gap="tight" items={[...[{
            term: 'Từ IP',
            description: entity?.contactform_ip
          }, {
            term: 'UUID',
            description: <Link url={'/activity/view/' + entity?.device_uuid}>{entity?.device_uuid}</Link>
          }], ...(entity?.user_id ? [] : [{
            term: 'Name',
            description: entity?.contactform_name
          }, {
            term: 'Email',
            description: entity?.contactform_email || <Badge></Badge>
          }, {
            term: 'Phone',
            description: entity?.contactform_numberphone || <Badge></Badge>
          }])]} />
            {!entity?.user_id && <Text as="p" variant="bodyMd" tone="subdued">
                <Link onClick={convertToCustomer}>Chuyển thành khách hàng</Link>
              </Text>}
            <br />
          </Card>
        </InlineGrid>

        <br />

        <div className="custom_tabs" style={{
        margin: '0 -15px'
      }}>
          <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>

          <div style={{
          margin: '15px'
        }}>
            {tabselected === 0 && <>
                <Comments objectId={contactform_id} commentType="contactform_comment" title="Thảo luận" />
              </>}
            {tabselected === 1 && <ActivityTracing deviceID={entity?.device_uuid} />}
          </div>
        </div>
      </Page>
    </>;
}