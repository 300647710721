import { Button, Card, DataTable, Divider, EmptyState, Link, TableData } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useGetOrder_bounty, useReCaculateOrderBounty } from 'queries/order_bounty.query';
import React, { useCallback, useEffect, useState } from 'react';
import { RefreshIcon } from '@shopify/polaris-icons';
import Capacity from 'components/Capacity';
export default function OrderBountyList({
  order_id
}: {
  order_id: string;
}) {
  const {
    data: entity,
    isLoading,
    refetch: getEntities
  } = useGetOrder_bounty(order_id);
  const {
    mutate: recaculatorOrderBounty,
    isPending: fetchingRecaculator
  } = useReCaculateOrderBounty();
  useEffect(() => {
    getEntities();
  }, [order_id]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (entity) setRows(entity);
  }, [entity]);
  const reCaculatorCallback = useCallback(() => {
    recaculatorOrderBounty(order_id);
  }, [order_id]);
  const handleSort = useCallback((index: number, direction: string) => {
    let sortedData = [...entity];
    switch (index) {
      case 0:
        // product_id
        if (direction === 'ascending') {
          let N = sortedData?.sort((a, b) => {
            return Number(b.product_id) - Number(a.product_id);
          });
          setRows(N);
        } else {
          let N = sortedData?.sort((a, b) => {
            return Number(a.product_id) - Number(b.product_id);
          });
          setRows(N);
        }
        break;
      case 1:
        if (direction === 'ascending') {
          let N = sortedData?.sort((a, b) => {
            return Number(b.bounty_value) - Number(a.bounty_value);
          });
          setRows(N);
        } else {
          let N = sortedData?.sort((a, b) => {
            return Number(a.bounty_value) - Number(b.bounty_value);
          });
          setRows(N);
        }
        break;
      case 2:
        if (direction === 'ascending') {
          let N = sortedData?.sort((a, b) => {
            return b.bounty_type.localeCompare(a.bounty_type);
          });
          setRows(N);
        } else {
          let N = sortedData?.sort((a, b) => {
            return a.bounty_type.localeCompare(b.bounty_type);
          });
          setRows(N);
        }
        break;
      case 3:
        if (direction === 'ascending') {
          let N = sortedData?.sort((a, b) => {
            return Number(b.receiver_id) - Number(a.receiver_id);
          });
          setRows(N);
        } else {
          let N = sortedData?.sort((a, b) => {
            return Number(a.receiver_id) - Number(b.receiver_id);
          });
          setRows(N);
        }
        break;
    }
  }, [entity]);
  return <Capacity current_user_can={'view_affiliates'} onFail={<>Bạn không có quyền truy cập.</>}>
      <Card>
        {rows && __helpers.isEmpty(rows) ? <EmptyState image={null} heading="Không có hoa hồng hay chiết khấu ở đây!" action={{
        content: 'Thử tính lại',
        icon: RefreshIcon,
        loading: fetchingRecaculator,
        onAction: () => reCaculatorCallback()
      }}>
            <p>Có thể bạn chưa cài đặt quy tắc hoa hồng, hoặc người mua chưa thỏa mãn các điều kiện.</p>
          </EmptyState> : <>
            <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'text', 'text', 'text']} headings={['Product', 'Giá trị', 'Loại bounty', 'Người nhận', 'Giải trình']} stickyHeader hideScrollIndicator hasZebraStripingOnData increasedTableDensity sortable={[true, true, true, true, false]} defaultSortDirection="ascending" initialSortColumnIndex={0} onSort={handleSort} fixedFirstColumns={1} rows={__helpers.isArray(rows) && rows.length > 0 ? rows.map(v => {
          return [v.product_id, __helpers.formatNumberWithCommas(v.bounty_value) + ' ' + v.wallet?.wallet_type?.wallet_sign, v.bounty_type, <Link url={`/customer/view/${v.receiver_id}`}>
                          {v.receiver_data?.display_name || v.receiver_data?.user_phonenumber || v.receiver_data?.user_email}
                        </Link>, v.bounty_explane];
        }) : []} />
            <br />
            <Divider />
            <br />
            <Button icon={RefreshIcon} loading={fetchingRecaculator} onClick={() => reCaculatorCallback()}>
              Tính lại
            </Button>
          </>}
      </Card>
    </Capacity>;
}