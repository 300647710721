import React, { useEffect, useState, useRef } from 'react';
import { Page } from '@shopify/polaris';
import { useSocket } from 'context/SocketContext';
import { useGetServerInfo } from 'queries/utils.query';
import { Helmet } from 'react-helmet-async';
import dateandtime from 'date-and-time';
export default function ServerInfo() {
  const {
    data
  } = useGetServerInfo();
  const {
    socket
  } = useSocket();
  const [logs, setLogs] = useState<string[]>([]);
  const logContainerRef = useRef<HTMLDivElement>(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  useEffect(() => {
    if (socket) {
      socket.on('console_log', (msg: string) => {
        setLogs(prevLogs => [...prevLogs, dateandtime.format(new Date(), 'HH:mm:ss') + ': ' + msg]);
      });
    }
    return () => {
      if (socket) {
        socket.off('console_log');
      }
    };
  }, [socket]);
  useEffect(() => {
    if (isAutoScroll && logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs, isAutoScroll]);
  const handleScroll = () => {
    if (!logContainerRef.current) return;
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = logContainerRef.current;
    setIsAutoScroll(scrollTop + clientHeight >= scrollHeight - 10);
  };
  function stripAnsiCodes(log: string): string {
    return log.replace(/\x1b\[\d{1,2}m/g, '');
  }
  return <>
      <Helmet>
        <title>Server Info</title>
      </Helmet>
      <Page title="Server Info">
        <pre>{JSON.stringify(data, null, 2)}</pre>
        <div ref={logContainerRef} onScroll={handleScroll} style={{
        maxHeight: '600px',
        overflowY: 'auto',
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap'
      }}>
          {logs.map((log, index) => <div key={index}>{stripAnsiCodes(log)}</div>)}
        </div>
      </Page>
    </>;
}