import { Banner, BlockStack, Button, Checkbox, ContextualSaveBar, Divider, Form, FormLayout, Icon, InlineGrid, Link, Page, Select, Text, TextField } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import QuickSearchProductCategory2 from 'components/quickSearchProductCategory';
import QuickSearchProductCollection from 'entities/product/product_collection/quickSearchProductCollection';
import helpers from 'helpers/index';
import { TypedProduct } from 'interface/product.model';
import __ from 'languages/index';
import { useCreateBanner, useDeleteBanner, useMutateGetBanner, useUpdateBanner } from 'queries/banner.query';
import { useCallback, useEffect, useState } from 'react';
import QuickSearchBannerTag from './quickSearchBannerTag';
import TextFieldRichText from 'components/TextFieldRichText';
import { DEEPLINK_SCREEN_NAMES, SCREEN_PATHS, SUGGEST_NOTIFICATION_DATA_KEY } from 'queries/notification.query';
import QuickSearchProductBrand from 'components/quickSearchProductBrand';
import QuickSearchVoucher from 'components/quickSearchVoucher';
import { LinkIcon } from '@shopify/polaris-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotification } from 'context/NotificationContext';
import { Helmet } from 'react-helmet-async';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { DeleteIcon } from '@shopify/polaris-icons';
export default function BannerModalAddAndEdit() {
  const [internalError, setInternalError] = useState('');
  const history = useNavigate();
  const {
    banner_id: current_banner_id
  } = useParams();
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: deleteEntity
  } = useDeleteBanner();
  const {
    mutateAsync: createEntity
  } = useCreateBanner();
  const {
    mutateAsync: updateEntity,
    isSuccess: updated
  } = useUpdateBanner();
  const {
    mutate: getEntity,
    data: entity
  } = useMutateGetBanner();
  const [selectedScreen, setSelectedScreen] = useState<any>(DEEPLINK_SCREEN_NAMES[0]);
  const [selectedParam, setSelectedParam] = useState<any>();
  useEffect(() => {
    if (!current_banner_id) return;
    getEntity(current_banner_id);
  }, [current_banner_id]);
  useEffect(() => {
    if (!updated) return;
    getEntity(current_banner_id);
  }, [updated]);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    banner_id: useField<string>({
      value: String(entity?.banner_id || ''),
      validates: []
    }),
    media_id: useField<string>({
      value: entity?.media_id || '',
      validates: []
    }),
    media_url: useField<string>({
      value: entity?.media?.media_url || '',
      validates: []
    }),
    banner_title: useField<string>({
      value: entity?.banner_title || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    banner_description: useField<string>({
      value: entity?.banner_description || '',
      validates: [lengthLessThan(65000, 'Không được dài hơn 65000 ký tự.')]
    }),
    banner_status: useField<number>({
      value: entity?.banner_status || 1,
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
      }]
    }),
    banner_size: useField<string>({
      value: entity?.banner_size || 'medium',
      validates: []
    }),
    banner_group: useField<string>({
      value: entity?.banner_group,
      validates: []
    }),
    banner_meta_action: useField<string>({
      value: entity?.banner_meta ? Object.keys(entity?.banner_meta)[0] : 'no_action',
      validates: []
    }),
    banner_meta_value: useField<string>({
      value: entity?.banner_meta ? Object.values(entity?.banner_meta)[0] : '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        let addProductCategory: Promise<any>;
        if (entity) {
          addProductCategory = updateEntity({
            banner_id: entity?.banner_id,
            media_id: values?.media_id,
            banner_title: values.banner_title,
            banner_description: values.banner_description,
            banner_status: values.banner_status,
            banner_size: values.banner_size,
            banner_meta: values.banner_meta_action && values.banner_meta_action !== "no_action" ? {
              [values.banner_meta_action]: values.banner_meta_value
            } : {},
            banner_group: values.banner_group
          });
        } else {
          addProductCategory = createEntity({
            media_id: values?.media_id,
            banner_title: values.banner_title,
            banner_description: values.banner_description,
            banner_status: values.banner_status,
            banner_size: values.banner_size,
            banner_meta: values.banner_meta_action && values.banner_meta_action !== "no_action" ? {
              [values.banner_meta_action]: values.banner_meta_value
            } : {},
            banner_group: values.banner_group
          });
        }
        let {
          data
        } = await addProductCategory;
        reset();
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const [deleting, setDeleting] = useState(false);
  const deleteBanner = useCallback(async (banner_id: any) => {
    const confirmed = await showDeleteConfirm('Xóa banner?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      try {
        setDeleting(true);
        await deleteEntity(banner_id);
        setDeleting(false);
      } catch (e) {}
    }
  }, []);
  const renderNotificationParamField = useCallback(() => {
    if (!SCREEN_PATHS[selectedScreen].includes('{params}')) {
      return null;
    }
    switch (selectedScreen) {
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY':
        return <QuickSearchProductCategory2 label="Chọn danh mục" current_category_id={[]} onClose={categories => setSelectedParam('category/' + categories?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION':
        return <QuickSearchProductCollection label="Chọn bộ sưu tập" current_collection_id={[]} onClose={collections => setSelectedParam('collection/' + collections?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND':
        return <QuickSearchProductBrand label="Chọn thương hiệu" current_brand_id={[]} onClose={brands => setSelectedParam('brand/' + brands?.pop())} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_PRODUCT_SCREEN':
        return <QuickSearchProduct2 label="Chọn sản phẩm" current_product_id={[]} onClose={products => setSelectedParam('@' + products?.pop()?.product_id)} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_VOUCHER_SCREEN':
        return <QuickSearchVoucher title="Chọn voucher" onClose={voucher => setSelectedParam(voucher?.voucher_id)} />;
      default:
        return <TextField label="Thông số" value={selectedParam || ''} onChange={setSelectedParam} autoComplete="off" />;
    }
  }, [selectedScreen]);
  return <>
      <Helmet>
        <title>{entity ? 'Chỉnh sửa banner' : 'Thêm mới banner'}</title>
      </Helmet>
      <Page narrowWidth title={entity ? 'Cập nhật Banner' : 'Thêm Banner'}
    // primaryAction={{
    //   content: entity ? __('update_label_button') : 'Lưu lại',
    //   loading: submitting,
    //   disabled: !dirty,
    //   onAction: submit,
    // }}
    secondaryActions={[{
      content: 'Xoá banner',
      icon: DeleteIcon,
      disabled: entity ? false : true,
      onAction: () => deleteBanner(entity?.banner_id),
      loading: deleting
    }]} backAction={{
      content: 'Back',
      onAction: () => history(-1)
    }}>
        <BlockStack>
          {internalError && <>
              <Banner tone="critical" onDismiss={() => setInternalError(null)}>
                {internalError}
              </Banner>
              <br />
            </>}

          <Form onSubmit={submit}>
            <BlockStack gap="200">
              <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
                <div className="d1">
                  <QuickUploadImage height={190} onSuccess={e => {
                  useFields.media_id.onChange(e.media_id);
                  useFields.media_url.onChange(e.media_url);
                }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.media_url.value)} />
                  <Text as="p" tone="critical">
                    {uploadError}
                  </Text>
                </div>
                <BlockStack gap="500">
                  <TextField label="Tên Banner" autoComplete="off" requiredIndicator {...fields.banner_title} />
                  <QuickSearchBannerTag current_group_id={entity?.banner_group} onClose={a => fields.banner_group.onChange(a)} />
                </BlockStack>
              </InlineGrid>

              <Banner tone="info">
                <Text as="p" variant="headingMd">
                  Ghi chú: Bạn có thể chọn màn hình và thông số phù hợp để tạo liên kết động cho banner này, gắn liên kết vào các vị trí mà bạn muốn
                  người dùng ấn vào sẽ mở màn hình tương ứng. Bạn có thể để liên kết vào ảnh.
                </Text>
                <FormLayout>
                  <FormLayout.Group>
                    <Select options={DEEPLINK_SCREEN_NAMES.map(screen => {
                    return {
                      label: `${SUGGEST_NOTIFICATION_DATA_KEY.includes(screen) ? '⭐ ' : ''}${__('MOBILE_' + screen)}`,
                      value: screen
                    };
                  })} label="Màn hình" value={selectedScreen} onChange={v => {
                    setSelectedScreen(v);
                    setSelectedParam(undefined);
                  }} />
                    {renderNotificationParamField()}
                  </FormLayout.Group>
                </FormLayout>

                <BlockStack gap="500">
                  <TextField autoComplete={'off'} label=" " value={`${process.env.REACT_APP_DEEPLINK_URL}://${SCREEN_PATHS?.[selectedScreen].replace('{params}', selectedParam || '')}`} readOnly prefix={<Icon source={LinkIcon} />} />
                  <Button onClick={() => {
                  if (SCREEN_PATHS[selectedScreen]?.includes('{params}') && !selectedParam) {
                    addNotification('error', 'Vui lòng nhập thông số hoặc chọn sản phẩm, danh mục,bộ sưu tập,v.v... tương ứng trước khi sao chép liên kết!');
                    return;
                  }
                  navigator.clipboard.writeText(`${process.env.REACT_APP_DEEPLINK_URL}://${SCREEN_PATHS?.[selectedScreen]?.replace('{params}', selectedParam || '')}`);
                  addNotification('info', 'Liên kết đã được sao chép!');
                }}>
                    Sao chép liên kết động
                  </Button>
                </BlockStack>
              </Banner>

              <TextFieldRichText error={fields.banner_description.error} requiredIndicator label={'Mô tả'} initialData={entity?.banner_description} onCallback={t => fields.banner_description.onChange(t)} />

              <Select label={'Kích thước'} options={[{
              label: __('banner_size_big'),
              value: 'big'
            }, {
              label: __('banner_size_medium'),
              value: 'medium'
            }, {
              label: __('banner_size_small'),
              value: 'small'
            }]} {...useFields.banner_size} />

              <br />
              <br />
              <Divider />
              <br />

              <Select label="Loại hành động, Nếu muốn loại bỏ nút khám phá ngay, chọn 'Không có thao tác'" options={[{
              label: __('no_action'),
              value: 'no_action'
            }, {
              label: __('action_link'),
              value: 'link'
            }, {
              label: __('action_external_link'),
              value: 'external_link'
            }, {
              label: __('action_product'),
              value: 'product'
            }, {
              label: __('action_category'),
              value: 'category'
            }, {
              label: __('action_collection'),
              value: 'collection'
            }]} {...useFields.banner_meta_action} />

              {(useFields.banner_meta_action.value === 'link' || useFields.banner_meta_action.value === 'external_link') && <TextField label="Đường dẫn" placeholder="Bắt đầu bằng https hoặc mailto" autoComplete="off" maxLength={250} {...useFields.banner_meta_value} />}
              {useFields.banner_meta_action.value === 'product' && <QuickSearchProduct2 allowMultiple={false} label="Chọn một sản phẩm" onClose={(products: TypedProduct[]) => useFields.banner_meta_value.onChange(products.map(item => item?.product_id).pop())} current_product_id={[useFields.banner_meta_value.value]} />}

              {useFields.banner_meta_action.value === 'category' && <QuickSearchProductCategory2 allowMultiple={false} label="Chọn một danh mục sản phẩm" onClose={(categories: string[]) => useFields.banner_meta_value.onChange(categories.pop())} current_category_id={[useFields.banner_meta_value.value]} />}

              {useFields.banner_meta_action.value === 'collection' && <QuickSearchProductCollection allowMultiple={false} onClose={(collections: string[]) => useFields.banner_meta_value.onChange(collections.pop())} current_collection_id={[useFields.banner_meta_value.value]} />}

              <br />
              <br />

              <Checkbox label="Cho phép hiển thị" helpText="Nếu tắt, Banner này sẽ không hiển thị trên ứng dụng hoặc website." checked={useFields.banner_status.value === 1} onChange={e => {
              useFields.banner_status.onChange(Number(e));
            }} />
            </BlockStack>
          </Form>
        </BlockStack>
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: submitting,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: reset
    }} />}

      <br />
      <br />
      <br />
      <br />
      <br />
    </>;
}